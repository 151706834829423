import React, { useState } from 'react';
import { ICataloguesList } from './ProductCataloguesTableView.template';
import { IPagination } from '../../../utils/types';
import TableV2, { ColumnType } from '../../organisms/Table/TableV2';
import ResourceStatusV2 from '../../atoms/ResourceStatusV2/ResourceStatusV2';
import Button from '../../atoms/Button/Button';
import { enumToString } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { VENDOR_PRODUCT_ROUTES } from '../../../utils/constant';

interface ICataloguesListingTableTemplateProps {
    cataloguesList: ICataloguesList[];
    pagination: IPagination;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    vendorId: number;
}


const ProductCataloguesListingTableTemplate: React.FC<ICataloguesListingTableTemplateProps> = ({ cataloguesList, pagination, handlePaginationChange, handleRowsPerPageChange, vendorId }) => {
const navigate = useNavigate();
const Action = (id: number) => (
    <div>
        <Button
            variant="tertiaryText"
            label={"View"}
            size='small'
            onClick={() => { navigate(`${VENDOR_PRODUCT_ROUTES.POST_PRODUCT}/${vendorId}/view/${id}`); }}
        />
    </div>
);


    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.page,
            isVisible: true,
            limit: pagination.size,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "SKU Code", key: "skuCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "Product Name", key: "upcTitle", type: "string" as ColumnType, props: { className: 'flex text-left ml-3.5' } },
            { label: "Thickness", key: "brandUpcCount", type: "number" as ColumnType, props: { className: '' } },
            { label: "Width", key: "availableStock", type: "number" as ColumnType, props: { className: '' } },
            { label: "Temper", key: "customers", type: "number" as ColumnType, props: { className: '' } },
            { label: "Length", key: "vendors", type: "number" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
        ],
    };

    const records = cataloguesList.map((catalogueList, index) => [
        pagination.page * pagination.size + index + 1,
        catalogueList.skuCode,
        catalogueList.productName,
        catalogueList.thickness ?? "-",
        catalogueList.width ?? "-",
        catalogueList.temper ?? "-",
        catalogueList.length ?? "-",
        enumToString(catalogueList.status),
        Action(catalogueList.id)
    ]);

    return (
        <div>
            <TableV2 schema={schema} records={records} />
        </div>
    );
};

export default ProductCataloguesListingTableTemplate;
