import React, { useState } from 'react'
import { createUseStyles } from 'react-jss';
import { isNumber } from 'lodash';
import uploadImageIcon from "../../../../../assets/images/uploadImage.svg";
import filledPlusIcon from '../../../../../assets/icons/filledPlus.svg'
import filledEditIcon from '../../../../../assets/icons/filledEdit.svg'
import { UploadedImageData } from '../../../../pages/DashBoard/FinishedProductCreation.page';
import ImageUploadModalTemplate from '../../../OnBoarding/ImageUploadModal.template';
import DisplayImageV2 from '../../../../molecules/ImageUpload/DisplayImageV2';

interface ImageUploadFormTemplateProps {
    mode: string,
    formik: any;
}

const useStyles = createUseStyles((theme: any) => ({
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    imagePreview: {
        width: "140px",
        height: "160px",
    },
    imageContainer: {
        width: '141px',
        height: '159px',
        borderRadius: '8px'
    },
    alphaBackground: {
        background: 'rgba(0,0,0,0.1)',
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary500}`
    }
}));

const ImageUploadFormTemplate: React.FC<ImageUploadFormTemplateProps> = ({ mode, formik }) => {
    const classes = useStyles();

    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFileTo] = useState<File | null>(null);
    const [hoverId, setHoverIdTo] = useState<string | null>(null);
    const [selectedPreviewImagePath, setSelectedPreviewImagePath] = useState<string>("");
    const [imageDialogOpen, setImageDialogOpenTo] = useState(false);
    const [selectedIndex, setSelectedImageIndex] = useState<number | null>(null)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNumber(selectedIndex) && formik.values?.images[selectedIndex] !== null && !(formik.values?.images[selectedIndex] instanceof File)) {
            formik.setFieldValue('imageListToDelete', [...formik.values?.imageListToDelete as number[], (formik.values?.images[selectedIndex] as UploadedImageData)?.id])
        }
        formik.setFieldValue(`images[${selectedIndex}]`, selectedFile as File)
        setSelectedFileTo(null);
        setIsImageModalOpen(false);
    };

    const handleUpload = (index: number) => (e: any) => {
        e.stopPropagation();
        setSelectedFileTo(null);
        setSelectedImageIndex(index);
        setIsImageModalOpen(true);
    }

    const handleImageModalClose = () => {
        setIsImageModalOpen(false);
    };

    const handleImageView = (path: string) => () => {
        !imageDialogOpen && setSelectedPreviewImagePath(path);
        setImageDialogOpenTo(!imageDialogOpen);
    }

    return (
        <>
            <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                <span className="text-base font-medium">Upload Images and Videos</span>
                <div className="flex gap-x-4">
                    {Array(formik.values?.images?.length < 5 ? formik.values?.images?.length + 1 : 5).fill(null).map((_, index) => (
                        <div key={index}>
                            {index !== formik.values?.images?.length ?
                                <div className={`overflow-hidden relative ${classes.imageContainer}`} onMouseEnter={() => setHoverIdTo(`EDIT-${index}`)} onMouseLeave={() => setHoverIdTo(null)}>
                                    <img className={`rounded-lg cursor-pointer ${classes.imagePreview}`}
                                        src={formik.values?.images?.[index] instanceof File ?
                                            URL?.createObjectURL(formik.values?.images?.[index] as File)
                                            :
                                            (formik.values?.images?.[index] as UploadedImageData)?.path} alt="icon" />
                                    {hoverId === `EDIT-${index}` && <span className={`${classes.alphaBackground} rounded-lg absolute top-0 right-0 w-full h-full z-0`}
                                        onClick={handleImageView(formik.values?.images?.[index] instanceof File ?
                                            URL?.createObjectURL(formik.values?.images?.[index] as File)
                                            :
                                            (formik.values?.images?.[index] as UploadedImageData)?.path)}>
                                        <img src={filledEditIcon} alt="" onClick={handleUpload(index)} className="absolute top-0 right-0 mt-2 mr-2 z-10 cursor-pointer" />
                                    </span>}
                                </div>
                                : (
                                    <div className={`relative `} onMouseEnter={() => setHoverIdTo(`ADD-${index}`)} onMouseLeave={() => setHoverIdTo(null)}>
                                        <img src={uploadImageIcon} alt="" className="z-0 h-full w-full" />
                                        {hoverId === `ADD-${index}` && <span className={`${classes.alphaBackground} rounded-lg absolute top-0 right-0 w-full h-full`}>
                                            <img src={filledPlusIcon} alt="" onClick={handleUpload(index)} className="absolute top-0 right-0 mt-2 mr-2 z-10 cursor-pointer" />
                                        </span>}
                                    </div>
                                )}
                            {
                                <ImageUploadModalTemplate
                                    dialogOpen={isImageModalOpen}
                                    setDialogOpen={handleImageModalClose}
                                    onSubmit={handleFileChange}
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFileTo}
                                />}
                        </div>
                    ))}
                </div>
            </div>

            <DisplayImageV2 path={selectedPreviewImagePath} imageDialogOpen={imageDialogOpen} setImageDialogOpenTo={setImageDialogOpenTo} />
        </>
    )
}

export default ImageUploadFormTemplate;