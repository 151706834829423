import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import TabsV2, { ITabsSchema } from '../../../molecules/TabsV2/TabsV2';
import { createUseStyles } from 'react-jss';
import { VALUES_ADDED_SERVICE_TYPE } from '../../../../utils/types';
import MachineListPage from './Machine/MachineList.page';
import USCListPage from './USC/USCList.page';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

const ServiceListPage: React.FC = () => {
    const classes = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(VALUES_ADDED_SERVICE_TYPE[searchParams.get('type') as keyof typeof VALUES_ADDED_SERVICE_TYPE] ?? VALUES_ADDED_SERVICE_TYPE.USC);

    useEffect(() => {
        if (isNaN(activeTab)) return;
        const level = Object.keys(VALUES_ADDED_SERVICE_TYPE).filter(key => isNaN(parseInt(key)))[activeTab - 1];
        setSearchParams((params: URLSearchParams) => {
            params.set('type', level)
            return params;
        })
    }, [activeTab])

    useEffect(() => {
        setActiveTab(VALUES_ADDED_SERVICE_TYPE[searchParams.get('type') as keyof typeof VALUES_ADDED_SERVICE_TYPE] ?? VALUES_ADDED_SERVICE_TYPE.USC)
    }, [searchParams.get('type')])

    const schema: ITabsSchema[] = [
        { label: 'USCs', component: <USCListPage /> },
        { label: 'Machines', component: <MachineListPage /> },
    ]

    return (
        <div className='grid gap-y-6'>
            <div className={`${classes.heading} text-lg font-medium`}>Value Added Services</div>
            <TabsV2 schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
        </div>
    )
}

export default ServiceListPage