import React, { useRef } from 'react';
import SecurePayments from '../../../assets/images/securePayments.svg';
import Carousel from "react-multi-carousel";
import CarouselRef from "react-multi-carousel";
import { createUseStyles, useTheme } from 'react-jss';
import TransparentProcess from '../../../assets/images/transparentProcess.svg';
import OriginalProduct from '../../../assets/images/originalProduct.svg';
import BestPrices from '../../../assets/images/bestPrices.svg';

const useStyles = createUseStyles({
    conatiner:{
      paddingTop: "26px",
      paddingBottom: "26px",    
      width:({width} : any) =>  width,
      height:({height}: any) => height
    },
    carouselContainer: {
        background: 'radial-gradient(46.27% 46.76% at 63.18% 43.61%, #FFF 0%, #EBEFF9 100%)',
        border:({theme} : any) =>  `1px solid ${theme.palette.v4.border._secondaryDarkAccent}`,
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        },
        "& .react-multi-carousel-list": {
            borderRadius: "8px",
        },
        width:({width} : any) =>  width,
        height:({height}: any) => height
    },
    carouselTitle: {
        color:({theme} : any) =>  theme.palette.text.primaryLightAccent
    },
    carouselDescription: {
        color:({theme} : any) =>  theme.palette.text.secondaryDark
    }
});

const carouselItems = [
    {
        title: 'Transparent Process',
        image: TransparentProcess
    },
    {
        title: 'Best Pricing in the Market',
        image: BestPrices
    },
    {
        title: '100% Original Products',
        image: OriginalProduct
    },
    {
        title: 'Secure Payments',
        image: SecurePayments
    },
];

interface ITermsAnsConditionsProps{
    height?:any,
    width?:any
}

const TermsAndConditionsCarousel: React.FC<ITermsAnsConditionsProps> = ({width="344px",height="131px"}) => {
    const theme = useTheme<Jss.Theme>();
    const classes = useStyles({theme,width,height})
    const carouselRef = useRef<CarouselRef>(null);
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        tablet: {
            breakpoint: { max: 1280, min: 980 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        mobile: {
            breakpoint: { max: 980, min: 670 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        "sm-mobile": {
            breakpoint: { max: 670, min: 0 },
            items: 1,
        },
    };

    return (
        <div className={`${classes.carouselContainer} rounded-lg`}>
            <Carousel
                responsive={responsive}
                infinite={true}
                partialVisible={false}
                ref={carouselRef}
                removeArrowOnDeviceType={["desktop", "tablet"]}
                autoPlay={true}
                autoPlaySpeed={2000}
                slidesToSlide={1}
            >
                {carouselItems.map((item) => (
                    <div className={`${classes.conatiner} pl-6 grid`} style={{ backgroundImage: `url(${item.image})`,backgroundSize:"cover" }}>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default TermsAndConditionsCarousel;