import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { MODE } from '../../../../../utils/constant';
import { FormControl } from '@mui/material';
import { HTTP_STATUS, VAS_MASTER_CATEGORY_TYPE } from '../../../../../utils/types';
import { useSnackbar } from '../../../../../hooks/useSnackBar';
import { IUSCs, Service, useVASMasterCategoryService } from '../../../../../services/useVASMasterCategoryService';
import AutoCompleteInputV2, { AutoCompleteOption } from '../../../../atoms/AutoCompleteInput/AutoCompleteInputV2';
import { useAuthenticatedUser } from '../../../../../hooks/useAuthenticatedUser';

interface UscAndServiceFormTemplateProps {
    mode: string,
    formik: any;
}

const useStyles = createUseStyles((theme: any) => ({
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
}));

const UscAndServiceFormTemplate: React.FC<UscAndServiceFormTemplateProps> = ({ mode, formik }) => {
    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const [serviceCategoryData, setServiceCategoryData] = useState<Service[] | null>(null);
    const [uscData, setUscData] = useState<IUSCs[] | null>(null);

    const updateSelectedService = (key: string) => (option: AutoCompleteOption | null) => {
        formik.setFieldValue(key, option);
        if (option && key === "usc") {
            formik.setFieldValue('uscId', option.id);
        }
        if (key === "serviceCategory") {
            formik.setFieldValue('usc', { label: "", id: "" });
            formik.setFieldValue('uscId', null);
        }
    };

    const fetchServiceCategory = (inputValue?: string) => {
        vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.SERVICE_CATEGORY, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' }, {})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setServiceCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Super Service data");
            })
    }

    const fetchUsc = (inputValue?: string) => {
        if (formik.values?.serviceCategory?.id) {
            vasMasterCategoryService.getUSCs({ vendorId: user?.businessId, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' }, { serviceCategoryIds: [formik.values?.serviceCategory?.id] })
                .then(res => {
                    if (res.status === HTTP_STATUS.OK) {
                        setUscData(res?.data?.data?.content)
                    }
                }).catch(error => {
                    console.error("Error Fetching USC: ", error);
                    showSnackbar('error', "Error while fetching USC data");
                })
        }
    }

    useEffect(() => {
        fetchServiceCategory();
    }, [])

    useEffect(() => {
        fetchUsc();
    }, [formik.values?.serviceCategory?.id]);

    const serviceCategoryOptions = useMemo(() => (
        serviceCategoryData?.map((serviceCategory: Service) => ({ label: serviceCategory.name, id: serviceCategory.id })) ?? []
    ), [serviceCategoryData])

    const uscOptions = useMemo(() => {
        return uscData?.map((usc: any) => ({ label: usc.uscCode, id: usc.id })) ?? []
    }, [uscData])

    return (
        <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
            <div className={`${classes.heading} text-base font-medium`}>Select USC and Service Category</div>
            <div className='grid grid-cols-2 gap-x-4'>
                <div className="grid gap-y-1">
                    <FormControl fullWidth>
                        <AutoCompleteInputV2
                            options={serviceCategoryOptions}
                            label="Select Service Category"
                            placeholder='Select Service Category'
                            onChange={updateSelectedService('serviceCategory')}
                            onSearch={fetchServiceCategory}
                            isDisabled={mode === MODE.VIEW}
                            value={formik.values?.serviceCategory}
                            error={!!formik.errors.serviceCategory && formik.touched.serviceCategory}
                        />
                        {formik.errors.serviceCategory?.id && formik.touched.serviceCategory?.id && (
                            <div className={`${classes.errorMessage}`}>
                                <small>{formik?.errors?.serviceCategory?.id}</small>
                            </div>
                        )}
                    </FormControl>
                </div>
                <div className="grid gap-y-1">
                    <FormControl fullWidth>
                        <AutoCompleteInputV2
                            options={uscOptions}
                            label="Select USC"
                            placeholder='Select USC'
                            onChange={updateSelectedService('usc')}
                            onSearch={fetchUsc}
                            isDisabled={mode === MODE.VIEW}
                            value={formik.values?.usc}
                            error={!!formik.errors.usc && formik.touched.usc}
                        />
                        {formik.errors.usc && formik.touched.usc && (
                            <div className={`${classes.errorMessage}`}>
                                <small>{formik?.errors?.usc?.id}</small>
                            </div>
                        )}
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

export default UscAndServiceFormTemplate;