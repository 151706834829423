import TableV2 from "../../organisms/Table/TableV2";
import { ColumnType } from "../../organisms/Table/Table";
import { IVendorProductPriceDetails } from "../../pages/VendorManagement/VendorProductPriceCreate.page";
import { HTTP_STATUS, IPagination } from "../../../utils/types";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { IProductPrice, useVendorPriceService } from "../../../services/useVendorPriceSerivce";
import { useEffect, useState } from "react";
import { IInventoryAddViewProps } from "./PriceManagementView.template";
import { initialPaginationValues } from "../../../utils/constant";
import ResourceStatusV2 from "../../atoms/ResourceStatusV2/ResourceStatusV2";
import moment from "moment";
import { enumToString } from "../../../utils/helper";

const PriceAddView: React.FC<IInventoryAddViewProps> = ({ warehouseId, productId }) => {

  const [vendorProductPrice, setVendorProductPriceTo] = useState<IVendorProductPriceDetails[]>([]);
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const { user } = useAuthenticatedUser();
  const vendorPriceService = useVendorPriceService();
  const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);

  const getVedorProductPrices = async () => {
    if (user?.businessId) {
      try {
        const params: IProductPrice = {
          page: pagination.page,
          size: pagination.size,
        };
        const vedorInventoriesResponse = await vendorPriceService.getAllPriceProducts(productId, params);
        if (vedorInventoriesResponse.status === HTTP_STATUS.OK) {
          const vendorInventoryData = vedorInventoriesResponse?.data?.data?.content.map((inventory: any) => ({
            id: inventory.warehouseId,
            marketPrice: inventory.marketPrice,
            reserveSalesPrice: inventory.reserveSalesPrice,
            validityTo: inventory.validityTo,
            validityFrom: inventory.validityFrom,
            name: inventory.warehouseLocation,
            status: inventory.status
          }));
          setVendorProductPriceTo(vendorInventoryData);
          setPagination((prevPagination: IPagination) => ({
            ...prevPagination,
            totalRecords: vedorInventoriesResponse?.data?.data?.totalElements,
          }));
        }
      } catch (error) {
        showSnackbar('error', 'Business Profile fetch failed');
      }
    }

  };

  useEffect(() => {
    getVedorProductPrices();
  }, [pagination.size, pagination.page]);

  const getTableColumns = () => [
    { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
    { label: "Location", key: "warehouseLocation", type: "custom" as ColumnType, props: { className: '' } },
    { label: "Market Price (CDP2)", key: "marketPrice", type: "custom" as ColumnType, props: { className: '' } },
    { label: "Reserve Sales Price (RSP)", key: "reserveSalesPrice", type: "custom" as ColumnType, props: { className: '' } },
    { label: "Price Validity (To)", key: "validityTo", type: "custom" as ColumnType, props: { className: '' } },
    { label: "Price Validity (From)", key: "validityFrom", type: "custom" as ColumnType, props: { className: '' } },
    { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
  ]
  const getRecords = () => {
    return vendorProductPrice.map((vendorInventory: IVendorProductPriceDetails, index: any) => ([
      index + 1,
      vendorInventory.name,
      vendorInventory.marketPrice,
      vendorInventory.reserveSalesPrice,
      moment(vendorInventory.validityTo).format("DD-MM-YYYY"),
      moment(vendorInventory.validityFrom).format("DD-MM-YYYY"),
      enumToString(vendorInventory?.status),
    ]));
  };

  const getSchema = () => ({
    id: "1",
    pagination: {
      total: 0,
      currentPage: 0,
      isVisible: false,
      limit: 1,
      handleChangePage: () => { },
      handleChangeRowsPerPage: () => { }
    },
    columns: getTableColumns() ?? []
  })
  return (
    <>
      <div className={` grid gap-4`}>
        <TableV2 schema={getSchema()} records={getRecords()} />
      </div>
    </>
  )
}
export default PriceAddView