import { useEffect } from "react";
import CustomRadioGroup from "../../molecules/CustomRadioGroup/CustomRadioGroup";
import { createUseStyles } from "react-jss";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";
import { IExistingProduct } from "./AddExistingProduct.template";
import { FormikProps } from "formik";
import { DELIVERY_INFORMATION } from "../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    title: {
        color: theme.palette.textV2.primary.primary900,
    },
    radioColor: {
        color: theme.palette.textV2.tertiary.primary500,
        width: "18%",
        padding: "16px 20px",
        display: "block",
        " & .MuiFormControlLabel-label": {
            display: "block",
            color: theme.palette.textV2.secondary.secondary800,
            fontSize: "18px",
            fontWeight: "600",
            marginTop: "12px"
        }
    },
    warningAsterisk: {
        marginTop: "-4px",
        color: theme.palette.textV2.primary.primary500
    }
}));

interface IDeliveryInformationProps {
    formik: FormikProps<IExistingProduct>;
    warehouseId: number;
    index: number;
}

const DeliveryInformationTemplate: React.FC<IDeliveryInformationProps> = ({ warehouseId, formik, index }) => {

    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue(`warehouseWiseProductDetails.${index}.deliveryTimeFrame`, event.target.value);
    };

    const onChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const parsedValue = value === '' ? '' : parseInt(value, 10);
        formik.setFieldValue(`warehouseWiseProductDetails.${index}.${name}`, parsedValue);
    };

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);

    return (
        <div className={`grid gap-y-4 rounded-xl ${classes.container} p-4`}>
            <div className="grid gap-y-3">
                <div className={`text-base font-medium ${classes.title}`}>Select Delivery Information <span className={classes.warningAsterisk}>*</span></div>
                <div>
                    <CustomRadioGroup
                        options={DELIVERY_INFORMATION}
                        value={formik.values.warehouseWiseProductDetails?.[index]?.deliveryTimeFrame || ""}
                        onChange={handleChange}
                        className={`${classes.radioColor}`} />
                </div>
            </div>
            <div className="grid gap-y-3">
                <div className={`text-base font-medium ${classes.title}`}>Order Details</div>
                <div className="w-1/3">
                    <div className="flex">
                        <TextFieldV2
                            placeholder='Enter Weight'
                            label="Minimum Order Quantity(MT)"
                            name="minimumOrderQuanity"
                            fullWidth
                            type="number"
                            className="p-4"
                            value={formik?.values?.warehouseWiseProductDetails?.[index]?.minimumOrderQuanity}
                            onChange={onChangeField}
                        />
                        <div className={classes.warningAsterisk}>
                            *
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default DeliveryInformationTemplate;
