import { useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { useEffect, useState } from "react";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { useVendorInventoryService } from "../../../services/useVendorInventoryService";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";
import { InputAdornment } from "@mui/material";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import deleteWhiteIcon from '../../../assets/icons/deleteWhiteIcon.svg';
import plusIcon from '../../../assets/icons/plusIcon.svg';
import { FormikErrors, FormikProps, useFormik } from 'formik';
import { IQuantityDiscount, IVendorCatalogueAddForm, IcatalogueProductDetails } from "../../pages/VendorCatalogue/VendorCatalogueAdd.page";
import UsnDetailsViewTemplate from "./UsnDetailsView.template";

export interface IVendorCatalogueUsnDetails {
    locationId: number;
    locationName: string;
    remainingStock: number;
    totalStock: number;
    deliveryInformation: string;
    minimumOrderQuantity: number;
    reserveSalesPrice: number;
    priceValidityFrom: string;
    priceValidityTo: string;
}
interface IPricingDetailFormProp {
    warehouseId: number;
    productId: number;
    formik: FormikProps<IVendorCatalogueAddForm>;
    index: number;
    vendorUsnDetails: IVendorCatalogueUsnDetails | undefined
}

const useStyles = createUseStyles((theme: any) => ({
    textHeading: {
        color: theme.palette.textV2.primary.primary900
    },
    text: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    container: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    searchIcon: {
        color: theme.palette.backgroundV2.secondary.secondary900
    },
    button: {
        backgroundColor: theme.palette.border.primaryDarkAccent,
        color: 'white',
    },
    textFeild: {
        width: "160px"
    },
    searchIcon2: {
        color: theme.palette.backgroundV2.tertiary.tertiary200
    },
    IconColor: {
        color: theme.palette.backgroundV2.primary.primary900
    },
    helperText: {
        position: 'absolute',
        bottom: '-16px',
    },

}));

const PricingDetailFormTemplate: React.FC<IPricingDetailFormProp> = ({ warehouseId, productId, formik, index, vendorUsnDetails }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [totalDiscount, setTotalDiscount] = useState<number>(0);

    const handleAction = (id: number) => {
        navigate('/dashboard/inventory-management/add');
    }

    const handleInputAdd = (localindex: number) => {
        const newQuantityDiscounts: IQuantityDiscount = {
            locationId: null,
            minQuantity: null,
            maxQuantity: null,
            minDiscount: null
        };
        formik.setFieldValue(`catalogueProductDetails.${index}.productDiscountDetails`, [...formik.values.catalogueProductDetails[index].productDiscountDetails, newQuantityDiscounts])
    }

    const handleInputDelete = (localindex: number) => {
        const updatedQuantityDiscounts = formik.values.catalogueProductDetails[index].productDiscountDetails.filter((_, i) => i !== localindex);
        formik.setFieldValue(`catalogueProductDetails.${index}.productDiscountDetails`, updatedQuantityDiscounts);
    }

    useEffect(() => {
        const list = formik.values.catalogueProductDetails[index]?.productDiscountDetails || [];
        let calculatedDiscount: number = 0;
        if (list.length > 0) {
            calculatedDiscount = Math.max(...list.map((item) => item?.minDiscount || 0));
        }
        setTotalDiscount(calculatedDiscount);
    }, [formik]);

    useEffect(()=>{
        formik.setFieldValue(`catalogueProductDetails.${index}.warehouseId`,warehouseId);
    },[warehouseId])

    useEffect(() => {
        for (let i = formik.values.catalogueProductDetails.length; i <= index; i++) {
            const newCatalogueProductDetails = {
                productId: productId,
                warehouseId: warehouseId,
                maximumRetailPrice: null,
                offerSalePrice: null,
                deliveryTerms: '',
                packagingType: '',
                productDiscountDetails: [{
                    locationId: null,
                    minQuantity: null,
                    maxQuantity: null,
                    minDiscount: null,
                    maxDiscount: null,
                }],
                productServicesDetails: [{
                    locationId: null,
                    machineId: null
                }],
                cGst: null,
                sGst: null,
                iGst: null,
                tcs: null,
                tax3: null,
            }
            formik.setFieldValue('catalogueProductDetails', [...formik.values.catalogueProductDetails, newCatalogueProductDetails]);
        }
    }, [index])

  
    return (
        <div className={` grid gap-6 pt-3`}>
                <UsnDetailsViewTemplate vendorUsnDetails={vendorUsnDetails} />
            <div className={`grid gap-4 p-4 border ${classes.container} rounded-xl `}>
                <div className={` ${classes.textHeading} text-base font-medium`}>Catalog Pricing (per MT)</div>
                <div className='flex gap-6 w-full  '>
                    <div className="w-1/2">
                        <TextFieldV2
                            className=''
                            size="medium"
                            placeholder="Enter Price"
                            type="number"
                            label='Maximum Retail Price (CDP 1= MRP)'
                            {...formik.getFieldProps(`catalogueProductDetails.${index}.maximumRetailPrice`)}
                            InputProps={{
                                className: "px-1 h-11 text-base ",
                                startAdornment: (
                                    <InputAdornment position="start" className='px-3'>
                                        <CurrencyRupeeIcon className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                            error={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.maximumRetailPrice &&
                                Boolean(formik.errors.catalogueProductDetails && Array.isArray(formik.errors.catalogueProductDetails) &&
                                    (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.maximumRetailPrice
                                )
                            }
                            helperText={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.maximumRetailPrice &&
                                Array.isArray(formik.errors.catalogueProductDetails) &&
                                (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.maximumRetailPrice
                            }
                            FormHelperTextProps={{ className: `${classes.helperText}` }}
                        />
                    </div>
                    <div className="w-1/2">
                        <TextFieldV2
                            className='mb-1'
                            size="medium"
                            placeholder="Enter Price"
                            label='Offer Sales Price (CDP 2 = CDP 1 - SD)'
                            type="number"
                            {...formik.getFieldProps(`catalogueProductDetails.${index}.offerSalePrice`)}
                            InputProps={{
                                className: "px-1 h-11 text-base ",
                                startAdornment: (
                                    <InputAdornment position="start" className='px-3'>
                                        <CurrencyRupeeIcon className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                            error={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.offerSalePrice &&
                                Boolean(formik.errors.catalogueProductDetails && Array.isArray(formik.errors.catalogueProductDetails) &&
                                    (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.offerSalePrice
                                )
                            }
                            helperText={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.offerSalePrice &&
                                Array.isArray(formik.errors.catalogueProductDetails) &&
                                (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.offerSalePrice
                            }
                            FormHelperTextProps={{ className: classes.helperText }}
                            />
                    </div>

                </div>
        </div>

            <div className={`grid gap-4 p-4 border ${classes.container} rounded-xl `}>
                <div className={` ${classes.textHeading} text-base font-medium`}> Quantity Discount</div>
                <div className="grid gap-6">
                    {formik.values.catalogueProductDetails[index]?.productDiscountDetails?.map((quantityDiscount: IQuantityDiscount, localIndex: number) => (
                        <div key={index} className='flex gap-6  items-end '>
                            <div className={` rounded-lg ${classes.button} w-12 h-12 text-lg flex justify-center text-center items-center font-semibold mb-1`}> {localIndex + 1} </div>
                            <div className={`w-1/5`}>
                                <TextFieldV2
                                    placeholder="Enter data"
                                    label='Quantity (Min.)/MT'
                                    {...formik.getFieldProps(`catalogueProductDetails.${index}.productDiscountDetails.${localIndex}.minQuantity`)}
                                    type="number"
                                    error={
                                        formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.productDiscountDetails &&
                                        formik.touched.catalogueProductDetails?.[index]?.productDiscountDetails?.[localIndex]?.minQuantity &&
                                        Boolean(formik.errors.catalogueProductDetails && Array.isArray(formik.errors.catalogueProductDetails) &&
                                            (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails &&
                                            Array.isArray((formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails) &&
                                            ((formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails as FormikErrors<IQuantityDiscount>[])[localIndex]?.minQuantity
                                        )
                                    }
                                    helperText={
                                        formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.productDiscountDetails &&
                                        formik.touched.catalogueProductDetails?.[index]?.productDiscountDetails?.[localIndex]?.minQuantity &&
                                        Array.isArray(formik.errors.catalogueProductDetails) && (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails &&
                                        Array.isArray((formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails) &&
                                        ((formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails as FormikErrors<IQuantityDiscount>[])[localIndex]?.minQuantity
                                    }
                                    FormHelperTextProps={{ className: `${classes.helperText}` }}
                                />
                            </div>
                            <div className={`w-1/5`}>
                                <TextFieldV2
                                    placeholder="Enter data"
                                    label='Quantity (Max.)/MT'
                                    size="medium"
                                    type="number"
                                    {...formik.getFieldProps(`catalogueProductDetails.${index}.productDiscountDetails.${localIndex}.maxQuantity`)}
                                    error={
                                        formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.productDiscountDetails &&
                                        formik.touched.catalogueProductDetails?.[index]?.productDiscountDetails?.[localIndex]?.maxQuantity &&
                                        Boolean(formik.errors.catalogueProductDetails && Array.isArray(formik.errors.catalogueProductDetails) &&
                                            (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails &&
                                            Array.isArray((formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails) &&
                                            ((formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails as FormikErrors<IQuantityDiscount>[])[localIndex]?.maxQuantity
                                        )
                                    }
                                    helperText={
                                        formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.productDiscountDetails &&
                                        formik.touched.catalogueProductDetails?.[index]?.productDiscountDetails?.[localIndex]?.maxQuantity &&
                                        Array.isArray(formik.errors.catalogueProductDetails) && (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails &&
                                        Array.isArray((formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails) &&
                                        ((formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails as FormikErrors<IQuantityDiscount>[])[localIndex]?.maxQuantity
                                    }
                                    FormHelperTextProps={{ className: `${classes.helperText} mt-4` }}
                                />
                            </div>
                            <div className={`w-1/4`}>
                                <TextFieldV2
                                    placeholder="Enter Amount"
                                    label='Discount'
                                    size="medium"
                                    type="number"
                                    className="w-52"
                                    {...formik.getFieldProps(`catalogueProductDetails.${index}.productDiscountDetails.${localIndex}.minDiscount`)}
                                    InputProps={{

                                        startAdornment: (
                                            <InputAdornment position="start" className='px-1                                                           '>
                                                <CurrencyRupeeIcon className={classes.searchIcon2} />
                                            </InputAdornment>
                                        )
                                    }}
                                    error={
                                        formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.productDiscountDetails &&
                                        formik.touched.catalogueProductDetails?.[index]?.productDiscountDetails?.[localIndex]?.minDiscount &&
                                        Boolean(formik.errors.catalogueProductDetails && Array.isArray(formik.errors.catalogueProductDetails) &&
                                            (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails &&
                                            Array.isArray((formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails) &&
                                            ((formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails as FormikErrors<IQuantityDiscount>[])[localIndex]?.minDiscount
                                        )
                                    }
                                    helperText={
                                        formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.productDiscountDetails &&
                                        formik.touched.catalogueProductDetails?.[index]?.productDiscountDetails?.[localIndex]?.minDiscount &&
                                        Array.isArray(formik.errors.catalogueProductDetails) && (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails &&
                                        Array.isArray((formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails) &&
                                        ((formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.productDiscountDetails as FormikErrors<IQuantityDiscount>[])[localIndex]?.minDiscount
                                    }
                                    FormHelperTextProps={{ className: `${classes.helperText}` }}
                                />
                            </div>
                            <div className={` rounded-lg ${classes.button} w-12 h-12 text-lg flex justify-center text-center items-center font-semibold mb-1`}>
                                {localIndex === 0 ? <img src={plusIcon} alt="deleteicon" onClick={() => handleInputAdd(localIndex)} /> : <img src={deleteWhiteIcon} alt="deleteicon" onClick={() => handleInputDelete(localIndex)} />}
                            </div>

                        </div>

                    ))}
                </div>
                <div className="w-1/3 ">
                    <TextFieldV2
                        size="medium"
                        placeholder="Enter Discount"
                        label='Maximum Quantity Discount (MQD)'
                        type="number"
                        value={totalDiscount}
                        InputProps={{
                            className: "px-1 h-11 text-base ",
                            startAdornment: (
                                <InputAdornment position="start" className='px-1'>
                                    <CurrencyRupeeIcon className={classes.searchIcon} />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
            </div>

            <div className={`grid gap-4 p-4 border ${classes.container} rounded-xl `}>
                <div className={` ${classes.textHeading} text-base font-medium`}>Tax Information</div>
                <div className="flex gap-3">
                    <div className="w-1.5/5">
                        <TextFieldV2
                            size="medium"
                            placeholder="Enter"
                            label='CGST Applicable'
                            type="number"
                            {...formik.getFieldProps(`catalogueProductDetails.${index}.cGst`)}
                            InputProps={{
                                className: "px-1 h-11 text-base ",
                                startAdornment: (
                                    <InputAdornment position="start" className='px-1'>
                                        <CurrencyRupeeIcon className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                            error={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.cGst &&
                                Boolean(formik.errors.catalogueProductDetails && Array.isArray(formik.errors.catalogueProductDetails) &&
                                    (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.cGst
                                )
                            }
                            helperText={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.cGst &&
                                Array.isArray(formik.errors.catalogueProductDetails) &&
                                (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.cGst
                            }
                            FormHelperTextProps={{ className: `${classes.helperText}` }}
                        />
                    </div>
                    <div className={` ${classes.IconColor} flex items-center pt-5 text-lg font-semibold`}>+</div>
                    <div className="w-1.5/5">
                        <TextFieldV2
                            size="medium"
                            placeholder="Enter"
                            label='SGST Applicable'
                            type="number"
                            {...formik.getFieldProps(`catalogueProductDetails.${index}.sGst`)}
                            InputProps={{
                                className: "px-1 h-11 text-base ",
                                startAdornment: (
                                    <InputAdornment position="start" className='px-1'>
                                        <CurrencyRupeeIcon className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                            error={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.sGst &&
                                Boolean(formik.errors.catalogueProductDetails && Array.isArray(formik.errors.catalogueProductDetails) &&
                                    (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.sGst
                                )
                            }
                            helperText={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.sGst &&
                                Array.isArray(formik.errors.catalogueProductDetails) &&
                                (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.sGst
                            }
                            FormHelperTextProps={{ className: `${classes.helperText}` }}
                        />
                    </div>
                    <div className={` ${classes.IconColor} flex items-center pt-5 text-lg font-semibold`}> Or </div>
                    <div className="w-1.5/5">
                        <TextFieldV2
                            size="medium"
                            placeholder="Enter"
                            label='IGST Applicable'
                            type="number"
                            {...formik.getFieldProps(`catalogueProductDetails.${index}.iGst`)}
                            InputProps={{
                                className: "px-1 h-11 text-base ",
                                startAdornment: (
                                    <InputAdornment position="start" className='px-1'>
                                        <CurrencyRupeeIcon className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                            error={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.iGst &&
                                Boolean(formik.errors.catalogueProductDetails && Array.isArray(formik.errors.catalogueProductDetails) &&
                                    (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.iGst
                                )
                            }
                            helperText={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.iGst &&
                                Array.isArray(formik.errors.catalogueProductDetails) &&
                                (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.iGst
                            }
                            FormHelperTextProps={{ className: `${classes.helperText}` }}
                        />
                    </div>
                    <div className="w-1.5/5">
                        <TextFieldV2
                            size="medium"
                            placeholder="Enter"
                            label='TCS Applicable'
                            type="number"
                            {...formik.getFieldProps(`catalogueProductDetails.${index}.tcs`)}
                            InputProps={{
                                className: "px-1 h-11 text-base ",
                                startAdornment: (
                                    <InputAdornment position="start" className='px-1'>
                                        <CurrencyRupeeIcon className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                            error={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.tcs &&
                                Boolean(formik.errors.catalogueProductDetails && Array.isArray(formik.errors.catalogueProductDetails) &&
                                    (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.tcs
                                )
                            }
                            helperText={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.tcs &&
                                Array.isArray(formik.errors.catalogueProductDetails) &&
                                (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.tcs
                            }
                            FormHelperTextProps={{ className: `${classes.helperText}` }}
                        />
                    </div>
                    <div className="w-1.5/5">
                        <TextFieldV2
                            size="medium"
                            placeholder="Enter"
                            label='Tax 3 Applicable'
                            type="number"
                            {...formik.getFieldProps(`catalogueProductDetails.${index}.tax3`)}
                            InputProps={{
                                className: "px-1 h-11 text-base ",
                                startAdornment: (
                                    <InputAdornment position="start" className='px-1'>
                                        <CurrencyRupeeIcon className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                            error={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.tax3 &&
                                Boolean(formik.errors.catalogueProductDetails && Array.isArray(formik.errors.catalogueProductDetails) &&
                                    (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.tax3
                                )
                            }
                            helperText={
                                formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.tax3 &&
                                Array.isArray(formik.errors.catalogueProductDetails) &&
                                (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.tax3
                            }
                            FormHelperTextProps={{ className: `${classes.helperText}` }}
                        />
                    </div>

                </div>
            </div>


        </div>
    )
}
export default PricingDetailFormTemplate