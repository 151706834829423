import React, { useMemo } from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { createUseStyles, useTheme } from "react-jss";

type CustomVariant = "contained" | "outlined" | "transparent" | "text" | "containedLight" | "underlined" | "containedDark"| "primaryContained" | "secondaryContained" | "tertiaryContained" | "primaryText" | "secondaryText" | "tertiaryText";
type CustomSize = "small" | "medium" | "large";

const useStyles = createUseStyles({ 
    contained: {
        color: ({ theme }: any) => theme.palette.v3.text._primaryLight,
        borderRadius: "10px",
        height: "48px",

        backgroundColor: ({ theme }: any) => theme.palette.v4.button.primaryLight,
        "&:hover": {
            backgroundColor: ({ theme }: any) => theme.palette.v4.button.primaryDark,
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.v4.text._secondaryLight,
            backgroundColor: ({ theme }: any) => theme.palette.v4.button._disabled,
        },
    },
    outlined: {
        color: ({ theme }: any) => theme.palette.button.secondaryDark,
        borderRadius: "6px",
        height: "40px",
        border: ({ theme }: any) => `1px solid ${theme.palette.button.secondaryDark}`,
    },
    transparent: {
        color: 'black',
        backgroundColor: 'transparent',
        borderRadius: "5px",
        height: "40px"
    },
    text: {
        color: ({ theme }: any) => theme.palette.text.primaryDark,
        backgroundColor: 'transparent',
        borderRadius: "5px",
        height: "40px"
    },
    iconButton: {
        color: ({ theme }: any) => theme.palette.button.primaryDark,
    },
    containedLight: {
        fontSize: "16px",
        fontWeight: 500,
        color: ({ theme }: any) => `${theme.palette.v3.text.secondaryDark} !important`,
        backgroundColor: ({ theme }: any) => `${theme.palette.v3.background.secondaryLight} !important`,
        borderRadius: "6px",
        height: "40px",
        "&:hover": {
            backgroundColor: ({ theme }: any) => `${theme.palette.button.primaryDark} !important`,
            color: 'white !important',
        }
    },
    underlined: {
        color: ({ theme }: any) => theme.palette.text.secondaryDark,
        textDecoration: "underline"
    },
    containedDark: {
        borderRadius: "10px",
        backgroundColor: ({ theme }: any) => theme.palette.v4.background._primaryDark,
                "&:hover": {
            backgroundColor: ({ theme }: any) => theme.palette.v4.button.primaryLight,
        },
        color: ({ theme }: any) => theme.palette.v3.text._primaryLight,
        fontFamily: 'IBM Plex Sans',
        fontWeight: 500,
        fontSize: "16px",
        height: "48px",
        padding: "12px 24px"
    },

    root:{
        minWidth: "auto",
        padding: "0px",
        lineHeight: "1 !important",
        "&:hover": {
            backgroundColor: "none",
        },
    },
    primaryContained: {
        color: 'white',
        borderRadius: "10px",
        backgroundColor: ({ theme }: any) => theme.palette.buttonV2.primaryContained.background.primaryLight,
        "&:hover": {
            backgroundColor: ({ theme }: any) => theme.palette.buttonV2.primaryContained.background.primaryLightAccent,
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryContained.text.secondaryLight,
            backgroundColor:({ theme }: any) => theme.palette.buttonV2.primaryContained.background.secondaryLight,
        },
        '&:focus': {
            border: ({ theme }: any) => `2px solid ${theme.palette.buttonV2.primaryContained.border.primaryLight}`
        },
        '&:active': {
            backgroundColor:({ theme }: any) => theme.palette.buttonV2.primaryContained.background.primaryDark,
          }, 
    },
    secondaryContained: {
        color: ({ theme }: any) => theme.palette.buttonV2.secondaryContained.text.primaryLight,
        borderRadius: "10px",
        backgroundColor: ({ theme }: any) => theme.palette.buttonV2.secondaryContained.background.primaryLight,
        "&:hover": {
            color: ({ theme }: any) => theme.palette.buttonV2.secondaryContained.text.primaryLightAccent,
            backgroundColor: ({ theme }: any) => theme.palette.buttonV2.secondaryContained.background.primaryLightAccent,
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.secondaryContained.text.secondaryLight,
            backgroundColor:({ theme }: any) => theme.palette.buttonV2.secondaryContained.background.secondaryLight,
        },
        '&:focus': {
            border: ({ theme }: any) => `2px solid ${theme.palette.buttonV2.secondaryContained.border.primaryLight}`
        },
        '&:active': {
            backgroundColor:({ theme }: any) => theme.palette.buttonV2.secondaryContained.background.primaryDark,
          },
    },
    tertiaryContained: {
        color: ({ theme }: any) => theme.palette.buttonV2.tertiaryContained.text.primaryLight,
        borderRadius: "10px",
        backgroundColor: "white",
        border:  ({ theme }: any) => `1px solid ${theme.palette.buttonV2.tertiaryContained.border.primaryLight}`,
        "&:hover": {
            backgroundColor: ({ theme }: any) => theme.palette.buttonV2.tertiaryContained.background.primaryLight,
        },
        "&:disabled": {
            color: ({ theme }: any) => "white",
            border:  ({ theme }: any) => `none`,
            backgroundColor: ({theme}:any) => theme.palette.v4.background._secondaryDark
        },
        '&:focus': {
            border: ({ theme }: any) => `2px solid ${theme.palette.buttonV2.tertiaryContained.border.primaryDark}`
        },
        '&:active': {
            color: "white",
            backgroundColor:({ theme }: any) => theme.palette.buttonV2.tertiaryContained.background.primaryDark,
          },
    },
    primaryText: {
        color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.secondaryDark,
        borderRadius: "10px",
        backgroundColor: "white",
        textDecoration: "underline",
        "&:hover": {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.primaryLight,
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.secondaryLight,
        },
        '&:focus': {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.secondaryDark,
            border: ({ theme }: any) => `1px solid ${theme.palette.buttonV2.primaryText.border.primaryLight}`
        },
        '&:active': {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.primaryDark,
          },
    },
    secondaryText: {
        color: ({ theme }: any) => theme.palette.buttonV2.secondaryText.text.primaryDark,
        borderRadius: "10px",
        backgroundColor: "white",
        textDecoration: "underline",
        "&:hover": {
            color: ({ theme }: any) => theme.palette.buttonV2.secondaryText.text.secondaryLight,
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.secondaryText.text.primaryLight,
        },
        '&:focus': {
            color: ({ theme }: any) => theme.palette.buttonV2.secondaryText.text.primaryDark,
            border: ({ theme }: any) => `1px solid ${theme.palette.buttonV2.secondaryText.border.primaryLight}`
        },
        '&:active': {
            color: ({ theme }: any) => theme.palette.buttonV2.secondaryText.text.secondaryDark,
          },
    },
    tertiaryText: {
        color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.secondaryDark,
        backgroundColor: "white",
        textDecoration: "underline",
        "&:hover": {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.primaryLight,
            textDecoration: "underline",
            backgroundColor: "white",
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.secondaryLight,
        }
    },
    label: {
       textTransform: "capitalize",
       fontFamily: 'IBM Plex Sans',
    },
    small: {
        height: "fit-content",
        fontSize: "14px",
        padding: "10px 16px",
    },
    medium: {   
        height: "fit-content",     
        fontSize: "16px",
        padding: "10px 20px",
    },
    large: {
        height: "fit-content",
        fontSize: "18px",
        padding: "12px 24px",
    },
});

interface ButtonProps extends Omit<MuiButtonProps, "variant"> {
    variant: CustomVariant;
    label?: string | null;
    isLoading?: boolean;
    iconButton?: React.ReactNode;
    size? : CustomSize
}

const Button: React.FC<ButtonProps> = ({
    variant,
    label,
    isLoading,
    iconButton,
    size = "medium",
    ...props
}) => {
    
    const theme = useTheme<Jss.Theme>();
    const styles = useStyles({ theme });

    const buttonView = useMemo(
        () => iconButton ? <span className={styles.iconButton}>{iconButton}</span> : label,
        [iconButton, label, styles]
    )
    return (
        <MuiButton
            {...props}
            className={`${styles[variant]} ${styles[size]} ${props.className ? props.className : ''} ${styles.label} font-medium`}
            disabled={props.disabled || !!isLoading}
            classes={{ root: styles.root }}
        >
            {isLoading ? <CircularProgress size={20}/> : buttonView}
        </MuiButton>
    );
};
export default Button;
