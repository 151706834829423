import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { IVendorProductPriceSectionProps } from '../../pages/VendorManagement/VendorProductPriceCreate.page';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { ADD_VENDOR_PRICE_STATES, HTTP_STATUS, VENDOR_PRODUCT_DETAILS_TYPE } from '../../../utils/types';
import Button from '../../atoms/Button/Button';
import ProductViewTemplate from '../DashBoard/Inventory Management/ProductView.template';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import VendorProductPriceViewTemplate from './VendorProductPriceView.template';
import { IProductData } from '../DashBoard/Inventory Management/SelectProduct.template';
import { CUSTOMER_ROUTES } from '../../../utils/constant';

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
}));

const PriceManagementReviewTemplate: React.FC<IVendorProductPriceSectionProps> = ({ formik, setCurrentSectionTo, warehouses, mode = 'ADD' }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { user } = useAuthenticatedUser();
    const vendorInventoryService = useVendorInventoryService();

    const [productsData, setProductsData] = useState<IProductData | null>(null);
    const handleCancel = () => {
        navigate(`${CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_LISTING}`);
    };
    const handleBack = () => {
        setCurrentSectionTo(ADD_VENDOR_PRICE_STATES.ADD_PRICE);
    }

    const getVedorProductbyId = async () => {
        if (user?.businessId) {
            try {
                const productId = formik?.values?.productId ?? 0;
                const ProductsResponse = await vendorInventoryService.getDetailedProduct(user?.businessId, productId, {vendorProductDetailsType: VENDOR_PRODUCT_DETAILS_TYPE.VENDOR_PRODUCT_USN_DETAILS} );
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data;
                    setProductsData({
                        id: productData?.id,
                        productId: productData?.productId,
                        skuCode: productData?.standardProductDetails.upcCode,
                        productName: productData?.productUpcTitle,
                        standardProductDetails: productData?.standardProductDetails,
                        vendorProductStockDetails: productData?.vendorProductStockDetails
                    });
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }
    };
    useEffect(() => {
        getVedorProductbyId();
    }, [user?.businessId]);

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);

    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
            {mode !== 'EDIT' && (
                    <>
                        <div className="grid gap-y-3 w-1/2">
                            <div className='flex gap-x-2 mx-auto'>
                                <div className={`${classes.activatedProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                                <div className={`${classes.barHeading} my-auto font-semibold`}>Select Product Type</div>
                            </div>
                            <div className={`${classes.activatedProgressBar} h-2.5 rounded`}></div>
                        </div>

                        <div className="grid gap-y-3 w-1/2">
                            <div className='flex gap-x-2 mx-auto'>
                                <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                                <div className={`${classes.barHeading} my-auto`}>Select Product</div>
                            </div>
                            <div className={`${classes.activatedProgressBar} h-2.5 rounded`}></div>
                        </div>
                    </>
                )}
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Add Pricing Details </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>
            <div className=' grid gap-4 '>
                <div className='flex  justify-between'>
                    <div className=' grid gap-1/2'>
                        <text className={`${classes.textHeading}text-base font-medium`}> Review</text>
                        <text className={`${classes.barHeading} text-xs font-normal`}> Please review the data you have entered </text>
                    </div>
                </div>
                <ProductViewTemplate
                    product={productsData}
                    showButton={false}
                    showUsnDetails={true}
                />
                <VendorProductPriceViewTemplate warehouseId={warehouses} formik={formik} />
            </div>
            <div className=' flex justify-between'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
                <div className='flex gap-6 '>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleBack}
                    />
                    <Button
                        variant="primaryContained"
                        label="+Add"
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </div>
        </div>
    )
}

export default PriceManagementReviewTemplate