import React, { useEffect, useState, useContext, useRef } from 'react'
import { useCatalogueService } from '../../../services/useCatalogueService';
import { Grid } from "@mui/material";
import { IWarehouse } from '../../templates/Catalogue/WarehouseLocation.template';
import { CATALOGUE_TYPE_STATES, CLASS_STATES, HTTP_STATUS } from '../../../utils/types';
import Select from '../../atoms/Select/Select';
import TextField from '../../atoms/Input/TextField';
import ProductUpcTemplate from '../../templates/Catalogue/ProductUpcAttribute.template';
import { createUseStyles } from 'react-jss';
import CatalogueImageTemplate, { IImage } from '../../templates/Catalogue/CatalogueImage.template';
import { convertObjectToOptions, makeRoute, snakeCaseToTitleCase } from '../../../utils/helper';
import { useLocation } from 'react-router-dom';
import { ICartRequestBody, useCartService } from '../../../services/useCartService';
import Breadcrumbs from '../../atoms/BreadCrumbs/BreadCrumbs';
import { CartContext } from '../../../contexts/CartContext';
import CatalogueDetailsTemplate from '../../templates/Catalogue/CatalogueDetails.template';
import AttributeSelectionTemplate from '../../templates/Catalogue/AttributeSelection.template';
import ProductDetailsTemplate from '../../templates/Catalogue/ProductDetails.template';
import TermsAndConditionsTemplate from '../../templates/Catalogue/TermsAndConditions.template';
import PriceDetailsTemplate from '../../templates/Catalogue/PriceDetails.template';
import brand from "../../../assets/icons/brand.svg";
import classType from "../../../assets/icons/classType.svg";
import shape from "../../../assets/icons/shape.svg";
import standard from "../../../assets/icons/standard.svg";
import grade from "../../../assets/icons/grade.svg";
import productCategory from "../../../assets/icons/productCategory.svg";
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import Carousel from "react-multi-carousel";
import CarouselRef from 'react-multi-carousel';
import MobilePriceDetails from '../../templates/Catalogue/MobilePriceDetails';
import { CART_LIFE_CYCLE, CUSTOMER_ROUTES, DEALS_OF_THE_DAY_DATA, PAYMENT_METHODS, REGEX, UNIT } from '../../../utils/constant';
import TermsAndConditionsCarousel from '../../templates/Catalogue/TermsAndConditionsCarousel.template';
import SingleProductUpcAttribute from '../../templates/Catalogue/SingleUpcAttribute.template';
import Disclaimer from '../../templates/Catalogue/Disclaimer.template';
import FAQTemplate from '../../templates/Catalogue/FAQ.template';
import ContactDetailsTemplate from '../../templates/Catalogue/ContactDetails.template';
import SocialLinks from '../../templates/Catalogue/SocialLinks.template';
import AddToFavouriteTemplate from '../../templates/Catalogue/AddToFavourite.template';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RequestForQuotationModalTemplate from '../../templates/Home/RequestForQuotationModal.template';
import Button from '../../atoms/Button/Button';
import PaymentDetailsTemplate from '../../templates/Catalogue/PaymentDetails.template';
import DeliveryDetailsTemplate from '../../templates/Catalogue/DeliveryDetails.template';
import ContactUs from '../ContactUs/ContactUs.page';
import DealsOfTheDayTemplate from '../../templates/Catalogue/DealsOfTheDay.template';
import SelectCustomizationTemplate, { IValueAddedService } from "../../templates/Catalogue/Customization/SelectCustomization.template";
import TermsConditionsTemplate from '../../templates/Catalogue/TermsConditions.template';
import PriceTrendsTemplate from '../../templates/Catalogue/PriceTrends.template';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import SimilarBrands from '../../templates/Catalogue/SimilarBrands.template';

export interface IAttribute {
    id: number;
    name: string;
    uom?: string;
    fieldType?: string;
    attributeOptions: number | string | string[];
}

export interface ICatalogue {
    id: number;
    name: string;
    description: string;
    classType: string;
    standard: string;
    grade: string;
    brand: string;
    manufacturer: string;
    minimumPrice: number;
    maximumPrice: number;
    catalogueAttributes: {
        SPECIFICATION: IAttribute[];
        CLASSIFICATION: IAttribute[];
        GENERALIZATION: IAttribute[];
    };
    catalogueImages: IImage[];
    packagingType: String[];
    customization: String[];
    isRepresentationImage: boolean;
    productCategory: string;
    shape: string;
}

export interface IUpcProperty {
    name: string;
    minimum?: number;
    maximum?: number;
    roomTemperature?: string;
    otherTemperature?: string;
    uom?: string;
}

export interface ICatalogueUpc {
    id: number;
    upcPrice: number;
    superCategory: string;
    mainCategory: string;
    productCategory: string;
    attributes: {
        SPECIFICATION: IAttribute[];
        CLASSIFICATION: IAttribute[];
        GENERALIZATION: IAttribute[];
    };
    hsnCodes: number[];
    tensileProperties: IUpcProperty[];
    chemicalComposition: IUpcProperty[];
    mechanicalProperties: IUpcProperty[];
    warehouseDetails: IWarehouse[];
    minimumOrderQuantity?: number;
}

export interface ICurrentCartItem {
    attributes: Record<string, any>;
    upc: ICatalogueUpc | null;
    warehouse: IWarehouse | null;
    packaging: string;
    quantity: string;
    secondarySkuId: number | null;
    paymentType: string | null;
}

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.text.secondaryDark
    },
    leftContainer: {
        width: "66.54%",
    },
    middleChild: {
        width: "60%"
    },
    rightContainer: {
        width: "31.85%",
    },
    catalogueName: {
        color: theme.palette.textV2.secondary.secondary900,
    },
    value: {
        color: theme.palette.v4.text.secondaryLight,
        fontSize: "16px",
    },
    quantityTerms: {
        color: theme.palette.action.danger
    },
    selectionWrapper: {
        borderRadius: "10px",
        border: `1px solid ${theme.palette.v4.border._primaryLight}`,
        color: theme.palette.v3.text._secondaryLight,
    },
    quantityInput: {
        borderRadius: "0px",
        border: `1px solid ${theme.palette.border._primaryDark}`,
        background: "#ffffff",
        borderRight: "none",
        padding: "8px"
    },
    description: {
        color: theme.palette.text._primaryDarkAccent
    },
    productDetails: {
        color: theme.palette.text._secondaryDark
    },
    termsAndConditions: {
        background: theme.palette.background._primaryLight
    },
    priceDetails: {
        background: ""
    },
    select: {
        "& .MuiInputBase-input": {
            color: theme.palette.v4.text.secondaryLightAccent,
            fontSize: "14px",
            background: "none",
        },
        "& .MuiSvgIcon-root": {
            width: "16px",
            height: "16px",
            cursor: "pointer",
            margin: "4px 8px 0px 0px",
            color: `${theme.palette.v4.text.secondaryLight}60`,
        },
        background: theme.palette.v3.background._primaryLight,
    },
    selectContainer: {
        marginTop: "-26px",
    },
    deliveryTerms: {
        width: "124px !important",
    },
    inStock: {
        height: "40px",
        borderRadius: "24px",
        background: theme.palette.v4.background.primaryDark,
        padding: "8px 16px",
        justifyContent: "center",
        alignItems: "center",
    },
    inStockValue: {
        color: theme.palette.v4.text.primaryDarkAccent,
    },
    uom: {
        background: theme.palette.background._primaryLghtAccent,
        border: `1px solid ${theme.palette.border._primaryDark}`,
        borderLeft: "none"
    },
    webContainer: {
        display: "grid",
    },
    mobileContainer: {
        display: "none",
    },
    termsAndConditionsWidth: {
        width: "90%"
    },
    inputContainer: {
        display: "flex",
        columnGap: "24px"
    },
    warehouse: {
        "& .MuiInputBase-input": {
            color: theme.palette.text.primaryLight,
        },
        border: `1px solid ${theme.palette.border.secondaryLight}`,
        background: "white",
    },
    inputField: {
        marginTop: "-10px",
    },
    location: {
        border: `1px solid ${theme.palette.v4.border.primaryLightAccent}`,
        background: "none !important",
    },
    catalogueDetails: {
        rowGap: "24px"
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        }
    },
    incrementDecrement: {
        background: theme.palette.v4.background.secondaryLightAccent,
        color: theme.palette.v4.text._primaryDark,
        boxShadow: `0px 4px 8px 3px ${theme.palette.v2.boxShadow.primaryDark}, 0px 1px 3px 0px ${theme.palette.v2.boxShadow.secondaryLight}`,
    },
    minimumQuantity: {
        color: theme.palette.textV2.tertiary.tertiary400,
    },
    count: {
        background: 'white',
        color: theme.palette.textV2.tertiary.tertiary700,
        border: `1px solid ${theme.palette.border.secondaryDarkAccent}`,
        fontSize: "16px",
        fontWeight: 500,
    },
    selectedUpc: {
        color: theme.palette.text.primaryLight,
        border: `1px solid ${theme.palette.v4.border._primaryDarkAccent}`,
        background: theme.palette.v3.background._primaryLight,
    },
    input: {
        border: "1px solid",
        borderColor: theme.palette.borderV2.tertiary.tertiary200,
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: 8,
        color: theme.palette.textV2.primary.primary900,
        "&:hover": {
            borderColor: theme.palette.borderV2.primary.primary500,
            borderWidth: 2,
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: theme.palette.borderV2.tertiary.tertiary200,
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `${theme.palette.borderV2.primary.primary500} !important`,
            borderWidth: "2px !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `${theme.palette.borderV2.primary.primary500} !important`,
        },
        "& .MuiInputBase-input": {
            padding: "16px !important"
        },
    },
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px"
    },
    "@media (max-width: 480px)": {
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "grid"
        },
        catalogueName: {
            color: theme.palette.textV2.primary.primary900,
        },
        termsAndConditionsWidth: {
            width: "auto"
        },
        inputContainer: {
            display: "grid",
            rowGap: "12px"
        },
        catalogueDetails: {
            rowGap: "16px"
        },
        carouselContainer: {
            "& .react-multiple-carousel__arrow--right": {
                right: "0 !important"
            },
            "& .react-multiple-carousel__arrow--left": {
                left: "0 !important"
            },
            "& .react-multi-carousel-dot-list": {
                top: "225px"
            },
            maxWidth: "287px",
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 20px`,
        },
        value: {
            fontSize: "16px",
        },
        selectContainer: {
            marginTop: "-10px"
        },
        input: {
            border: "1px solid",
            borderColor: theme.palette.borderV2.tertiary.tertiary200,
            fontSize: "14px",
            fontWeight: 500,
            borderRadius: 8,
            color: theme.palette.textV2.primary.primary900,
            "&:hover": {
                borderColor: theme.palette.borderV2.primary.primary500,
                borderWidth: 2,
                outline: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.borderV2.tertiary.tertiary200,
            },
            "&:focus-within": {
                outline: "none",
                borderColor: `${theme.palette.borderV2.primary.primary500} !important`,
                borderWidth: "2px !important",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: `${theme.palette.borderV2.primary.primary500} !important`,
            },
            "& .MuiInputBase-input": {
                padding: "16px !important"
            },
        },
        label: {
            color: theme.palette.textV2.tertiary.tertiary600,
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16.1px"
        },
        selectionWrapper: {
            width: "fit-content"
        },
    },
}));

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 4,
        partialVisibilityGutter: 10
    },
    tablet: {
        breakpoint: { max: 1280, min: 980 },
        items: 3,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 980, min: 670 },
        items: 2,
        partialVisibilityGutter: 10
    },
    "sm-mobile": {
        breakpoint: { max: 670, min: 0 },
        items: 1
    }
};

const CatalogueDetails: React.FC = () => {

    const classes = useStyles();

    const { user, syncAuthDialogActive } = useAuthenticatedUser();

    const cartContextData = useContext(CartContext);

    const catalogueService = useCatalogueService();
    const cartService = useCartService();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [catalogueId, setCatalogueIdTo] = useState<number | null>(null);
    const [catalogue, setCatalogueTo] = useState<ICatalogue | null>(null);
    const [catalogueUpc, setCatalogueUpcTo] = useState<ICatalogueUpc[] | null>(null);
    const [packagingTypes, setPackagingTypesTo] = useState<Record<string, string>>({});
    const [currentCartItem, setCurrentCartItemTo] = useState<ICurrentCartItem>({
        attributes: {},
        upc: null,
        warehouse: null,
        packaging: "WITHOUT_PACKAGING",
        quantity: "",
        secondarySkuId: null,
        paymentType: PAYMENT_METHODS.CASH_PAYMENT
    });
    const [moq, setMOQ] = useState<string>('');

    const carouselRef = useRef<CarouselRef>(null);

    const CATALOGUE_DETAILS_SCHEMA = [{ label: "brand", icon: brand }, { label: "classType", icon: classType }, { label: "shape", icon: shape }, { label: "standard", icon: standard }, { label: "grade", icon: grade }, { label: "productCategory", icon: productCategory }];

    const gridTemplate = { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 };

    const [valueAddedService, setValueAddedServiceTo] = useState<IValueAddedService>({
        shearing: [],
        slitting: [],
    })
    const [productCombination, setProductCombination] = useState<boolean | null>(null);
    const [paymentType, setPaymentType] = useState<string | null>(PAYMENT_METHODS.CASH_PAYMENT);

    const handleWarehouseChange = (e: any) => {
        setCurrentCartItemTo({
            ...currentCartItem,
            warehouse: e.target.value,
        });
    };

    const scrollToLabel = () => {
        const label = document.getElementById('scrollToLabel');
        if (label) {
            label.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        const label2 = document.getElementById('scroll-mobile');
        if (label2) {
            label2.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleAddToCart = async () => {
        if (!user) {
            syncAuthDialogActive()
            return;
        }
        const requiredQuantity = parseFloat(currentCartItem.quantity);
        if (!REGEX.FLOAT.test(currentCartItem.quantity?.toString())) {
            return;
        }
        if (catalogue && currentCartItem && currentCartItem.upc && currentCartItem.warehouse && currentCartItem.paymentType) {
            let cartRequestBody: ICartRequestBody = {
                catalogueName: catalogue.name,
                upcId: currentCartItem.upc.id,
                warehouseId: currentCartItem.warehouse?.id,
                quantity: requiredQuantity,
                uom: currentCartItem.warehouse?.uom || "MT",
                packagingType: currentCartItem.packaging,
                secondarySkuId: null,
                valueAddedServices: valueAddedService,
                paymentType: currentCartItem.paymentType
            }
            await cartService.saveToCart(cartRequestBody);
            await cartContextData.syncCart(CART_LIFE_CYCLE.ADD_PRODUCT);
        }
    }

    const handleQuantityChange = (eventOrValue: any) => {
        const quantity = typeof eventOrValue === 'string' ? eventOrValue : eventOrValue.target.value;
        if (REGEX.QUANTITY.test(quantity) || quantity === '') {
            setCurrentCartItemTo({
                ...currentCartItem,
                quantity
            });
        }
    };

    const handlePaymentTypeChange = (paymentTypeMethod: string) => {
        setCurrentCartItemTo({
            ...currentCartItem,
            paymentType: paymentTypeMethod
        });
        setPaymentType(paymentTypeMethod);
    };

    const convertWarehousesToOptions = (warehouses: any): any => {
        return warehouses.map((warehouse: any) => ({
            value: warehouse,
            label: warehouse.name, 
        }));
    };

    const findMOQ = (attributes: IAttribute[]) => {
        return attributes?.find((attribute: IAttribute) => attribute.name === "MOQ")?.attributeOptions ?? 1;
    };

    useEffect(() => {
        const catalogueIdString = queryParams.get('catalogueId');
        if (catalogueIdString !== null) {
            const parsedCatalogueId = parseInt(catalogueIdString, 10);
            if (!isNaN(parsedCatalogueId)) {
                setCatalogueIdTo(parsedCatalogueId);
            }
        }
    }, [location.search])

    useEffect(() => {
        if (catalogueId) {
            catalogueService.getCatalogueById(catalogueId)
                .then((response) => {
                    if (response.status === HTTP_STATUS.OK) {
                        setCatalogueTo(response.data.data ? response.data.data : null);
                        setPackagingTypesTo(Object.fromEntries(response.data.data?.packagingTypes?.map((key: string) => [key, key])) ?? {});
                        setCurrentCartItemTo(prevCartItem => ({ ...prevCartItem, packaging: response.data.data?.packagingTypes[0] || null }));
                    }
                })
                .catch((error) => {
                    console.error('Error', error);
                    setCatalogueTo(null);
                });
        }
    }, [catalogueId]);

    useEffect(() => {
        if (catalogueId) {
            catalogueService.getAllUpcsByCatalogueId(catalogueId)
                .then((response) => {
                    setCatalogueUpcTo(response.status === HTTP_STATUS.OK && response.data.data ? response.data.data : null);
                    setMOQ(response?.data?.data[0].minimumOrderQuantity)
                    setCurrentCartItemTo(prevCartItem => ({
                        ...prevCartItem,
                        warehouse: response?.data?.data?.[0]?.warehouseDetails?.[0],
                        upc: response?.data?.data?.[0]
                    }));
                },)
                .catch((error) => {
                    console.error('Error', error);
                    setCatalogueUpcTo(null);
                })
        }
    }, [catalogueId]);

    useEffect(() => {
        setCurrentCartItemTo(prevCartItem => ({ ...prevCartItem, quantity: moq }));
    }, [moq]);


    const alterQuantity = (alterQuantityUnit: number) => () => {
        const currentQuantity = (parseFloat(currentCartItem.quantity) || 0).toString();
        handleQuantityChange(String(parseFloat(Math.max(parseFloat(currentQuantity) + alterQuantityUnit, parseFloat(moq)).toFixed(2))));
    };

    const increaseQuantity = () => {
        if (currentCartItem.warehouse) {
            alterQuantity(1)();
        }
    };

    const decreaseQuantity = () => {
        if (currentCartItem.warehouse) {
            alterQuantity(-1)();
        }
    };
    return (
        <div>
            <div className={`${classes.webContainer} max-container px-16 m-auto`}>
                <div className="flex justify-between mt-6">
                    <Breadcrumbs crumbs={[
                        {
                            link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: catalogue?.classType ?? CLASS_STATES.STANDARD, catalogueTypes: CATALOGUE_TYPE_STATES.CURRENT_INVENTORY } }),
                            label: catalogue?.classType === CLASS_STATES.STANDARD ? snakeCaseToTitleCase(CLASS_STATES.STANDARD) : snakeCaseToTitleCase(CLASS_STATES.NONSTANDARD)
                        },
                        {
                            link: "",
                            label: "View"
                        }
                    ]} />
                    <ContactUs />
                </div>
                <div className='flex gap-x-6'>
                    <div className={`grid mt-6 ${classes.leftContainer}`}>
                        <div className="w-full flex gap-x-6 ">
                            <div className="grid gap-y-4 h-fit">
                                <CatalogueImageTemplate isSharable={false} catalogueImages={catalogue?.catalogueImages} isRepresentationImage={catalogue?.isRepresentationImage} />
                                <div><TermsAndConditionsCarousel /></div>
                                <AddToFavouriteTemplate id={catalogue?.id} catalogueType={CLASS_STATES.STANDARD} />
                            </div>
                            <div className={`${classes.middleChild} grid gap-y-6 h-fit mt-0 m-auto`}>
                                <div>
                                    <CatalogueDetailsTemplate catalogue={catalogue} CATALOGUE_DETAILS_SCHEMA={CATALOGUE_DETAILS_SCHEMA} gridTemplate={gridTemplate} />
                                </div>
                                {/* TODO: Will uncomment after getting data from backend */}
                                {/* {DEALS_OF_THE_DAY_DATA.length && <DealsOfTheDayTemplate />} */}
                                <div>
                                    <SingleProductUpcAttribute attributes={catalogue?.catalogueAttributes?.SPECIFICATION || {}} />
                                </div>
                                {currentCartItem?.upc && Object.keys(currentCartItem?.upc).length > 0 && (
                                    <div className="flex gap-x-3">
                                        <div className="grid">
                                            <div className={`${classes.value} pb-10 text-base font-medium`}>
                                                Packaging
                                            </div>
                                            <div className={`${classes.selectContainer}`}>
                                                <TextField
                                                    label=""
                                                    variant="standard"
                                                    value={snakeCaseToTitleCase(currentCartItem.packaging)}
                                                    onChange={(event) => {
                                                        setCurrentCartItemTo({
                                                            ...currentCartItem,
                                                            packaging: event.target.value,
                                                        });
                                                    }}
                                                    fullWidth
                                                    className={`justify-center !pl-3 h-12 !py-0 rounded-lg ${classes.select}`}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        readOnly: true,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {currentCartItem?.upc?.warehouseDetails?.length > 0 && (<div className="grid gap-y-6">
                                            <div className={`${classes.value} text-base font-medium`}>Location</div>
                                            <div className={`flex ${classes.inputField} h-12 rounded`}>
                                                <Select
                                                    label=""
                                                    variant='standard'
                                                    disableUnderline={true}
                                                    value={currentCartItem.warehouse}
                                                    fullWidth
                                                    onChange={(e: any) => handleWarehouseChange(e)}
                                                    className={`${classes.select} ${classes.location} !m-0 !px-3 rounded-lg capitalize h-full`}
                                                    name="warehouse"
                                                    id="warehouse"
                                                    IconComponent={(props: any) => <ExpandMoreIcon {...props} />}
                                                    options={convertWarehousesToOptions(currentCartItem?.upc?.warehouseDetails || [])}
                                                />
                                            </div>
                                        </div>)}
                                        {currentCartItem?.upc?.warehouseDetails?.length > 0 && (<div className="grid gap-y-6">
                                            <div className={`${classes.value} text-base font-medium`}>Delivery Terms</div>
                                            <div className={`flex ${classes.inputField} ${classes.deliveryTerms} h-12 rounded`}>
                                                <TextField
                                                    label=""
                                                    variant="standard"
                                                    value={"Within 3 Days"}
                                                    className={`${classes.select} justify-center align-center !m-0 !px-3 rounded-lg capitalize h-full`}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        readOnly: true,
                                                    }}
                                                />
                                            </div>
                                        </div>)}
                                        {currentCartItem?.upc?.warehouseDetails?.length > 0 && <div className={`${classes.inStock} mt-12 whitespace-nowrap`}>
                                            <span className={`${classes.inStockValue} font-medium text-sm`}>In Stock</span>
                                        </div>}
                                    </div>
                                )}
                                {currentCartItem?.upc && <div className="flex justify-between">
                                    <div className={`${classes.selectionWrapper} text-sm p-3 font-bold cursor-pointer`} onClick={scrollToLabel}>
                                        Explore other Brands
                                    </div>
                                </div>}
                            </div>
                        </div>

                        <div className={`border-b py-6`}>
                            <SelectCustomizationTemplate
                                catalogue={catalogue}
                                secondaryCatalogue={null}
                                quantity={parseFloat(currentCartItem.quantity)}
                                selectedUpc={currentCartItem?.upc}
                                valueAddedService={valueAddedService}
                                setValueAddedServiceTo={setValueAddedServiceTo}
                            />
                        </div>

                        <div className={`border-b py-10`}><PaymentDetailsTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                        <div className={`border-b py-10`}><DeliveryDetailsTemplate /></div>
                        <div className={`py-10 border-b`}>{currentCartItem?.upc && currentCartItem && Object.keys(currentCartItem?.upc).length > 0 && <ProductUpcTemplate upc={currentCartItem?.upc} />}</div>
                        {catalogue && currentCartItem.upc !== null && <div className={`border-b py-10`}><SimilarBrands  currentCartItem={currentCartItem} catalogue={catalogue} catalogueType={CATALOGUE_TYPE_STATES.CURRENT_INVENTORY}/></div>}
                        <div className={`border-b py-10`}><PriceTrendsTemplate /></div>
                        <div className={`border-b py-10`}><FAQTemplate /></div>
                        <div className={`pt-10`}><TermsConditionsTemplate /></div>
                        <div className={` py-10`} id="scrollToDisclaimer"><Disclaimer /></div>
                    </div>
                    <div className={classes.rightContainer}>
                        <div className={`pt-6 sticky top-0 grid gap-y-4`}>
                            {catalogue && <PriceDetailsTemplate handleAddToCart={handleAddToCart} paymentType={currentCartItem.paymentType} moq={moq} handleQuantityChange={handleQuantityChange} minimumPrice={catalogue.minimumPrice} maximumPrice={catalogue.maximumPrice} quantity={currentCartItem.quantity} gst={18} selectedUpc={currentCartItem?.upc} warehouse={currentCartItem.warehouse} uom={(currentCartItem?.warehouse?.uom)?.toString() || ""} catalogueType={CATALOGUE_TYPE_STATES.CURRENT_INVENTORY} catalogue={catalogue} productCombination={productCombination} />}
                        </div>
                    </div>
                </div>
            </div>

            <div className={`gap-y-6 ${classes.mobileContainer}`}>
                <div className={`grid gap-y-6 px-4`}>
                    <div className="">
                        <Breadcrumbs crumbs={[
                            {
                                link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: catalogue?.classType ?? CLASS_STATES.STANDARD, catalogueTypes: CATALOGUE_TYPE_STATES.CURRENT_INVENTORY } }),
                                label: catalogue?.classType === CLASS_STATES.STANDARD ? snakeCaseToTitleCase(CLASS_STATES.STANDARD) : snakeCaseToTitleCase(CLASS_STATES.NONSTANDARD)
                            },
                            {
                                link: "",
                                label: "View"
                            }
                        ]} />
                    </div>
                    <div className="w-full grid gap-y-2 mb-2">
                        <div className={`${classes.catalogueName} text-lg font-semibold`}>{catalogue?.name}</div>
                        <CatalogueImageTemplate isSharable={false} catalogueImages={catalogue?.catalogueImages} isRepresentationImage={catalogue?.isRepresentationImage} />
                    </div>
                    <div><SingleProductUpcAttribute attributes={catalogue?.catalogueAttributes?.SPECIFICATION || {}} /> </div>
                    <div className='border-t'> </div>
                    {currentCartItem?.upc && Object.keys(currentCartItem?.upc).length > 0 && (
                        <div className={`${classes.inputContainer}`}>
                            <TextFieldV2
                                label="Packaging"
                                value={snakeCaseToTitleCase(currentCartItem.packaging)}
                                onChange={(event) => {
                                    setCurrentCartItemTo({
                                        ...currentCartItem,
                                        packaging: event.target.value,
                                    });
                                }}
                                fullWidth
                                disabled={true}
                            />

                            {currentCartItem?.upc?.warehouseDetails?.length > 0 && (<div className="grid gap-y-2">
                                <div className={classes.label}>
                                    Location
                                </div>
                                <Select
                                    label=""
                                    value={currentCartItem.warehouse}
                                    fullWidth
                                    onChange={(e: any) => handleWarehouseChange(e)}
                                    name="warehouse"
                                    id="warehouse"
                                    options={convertWarehousesToOptions(currentCartItem?.upc?.warehouseDetails || [])}
                                    className={classes.input}
                                />
                            </div>)}

                            {currentCartItem?.upc?.warehouseDetails?.length > 0 && (
                                <TextFieldV2
                                    fullWidth
                                    label="Delivery Terms"
                                    value={"Within 3 Days"}
                                    disabled={true}
                                />
                            )}

                            {currentCartItem?.upc?.warehouseDetails?.length > 0 && <div className={`${classes.inStock} whitespace-nowrap w-fit`}>
                                <span className={`${classes.inStockValue} font-medium text-sm`}>In Stock</span>
                            </div>}

                            {currentCartItem?.upc && <div className="flex justify-between">
                                <div className={`${classes.selectionWrapper} text-sm py-2.5 px-4 font-medium cursor-pointer`} onClick={scrollToLabel}>
                                    Explore other Brands
                                </div>
                            </div>}
                        </div>
                    )}

                    <div className='border-t'> </div>
                    <div className="grid gap-y-3">
                        <div className={`${classes.value} font-medium`}>Enter Quantity (MT)</div>
                        <div className='flex gap-x-4'>
                            <div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} onClick={decreaseQuantity} style={{ cursor: (currentCartItem.warehouse && (parseFloat(currentCartItem.quantity) > parseFloat(moq))) ? 'pointer' : 'not-allowed' }}><RemoveIcon /></div>
                            <input type="text" className={`w-24 h-9 rounded-lg flex text-center justify-center items-center gap-2  ${moq === "" ? classes.selectedUpc : classes.count}`} value={moq === "" ? "" : currentCartItem.quantity} disabled={moq === "" || !currentCartItem.warehouse} onChange={event => handleQuantityChange(event.target.value)} />
                            <div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} onClick={increaseQuantity} style={{ cursor: currentCartItem.warehouse ? 'pointer' : 'not-allowed' }}><AddIcon /></div>
                        </div>
                        {!(moq === "") && <div className={`text-xs font-semibold ${classes.minimumQuantity}`}>Minimum Order Quantity : {moq}{UNIT.UOM} </div>}
                    </div>
                    <div className='border-t'> </div>
                    <div className={``}>
                        <SelectCustomizationTemplate
                            catalogue={catalogue}
                            secondaryCatalogue={null}
                            quantity={parseFloat(currentCartItem.quantity)}
                            selectedUpc={currentCartItem?.upc}
                            valueAddedService={valueAddedService}
                            setValueAddedServiceTo={setValueAddedServiceTo}
                        />
                    </div>
                    <div className='border-t'> </div>
                    <div className={``}><PaymentDetailsTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                    <div className='border-t'> </div>
                    <div className={``}><DeliveryDetailsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``}>{currentCartItem?.upc && currentCartItem && Object.keys(currentCartItem?.upc).length > 0 && <ProductUpcTemplate upc={currentCartItem?.upc} />}</div>
                    <div className='border-t'> </div>
                    {/* <div className={``} id='scroll-mobile'><SimilarBrands  currentCartItem={currentCartItem}/></div> */}
                    <div className='border-t'> </div>
                    <div className={``}><PriceTrendsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``}><FAQTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``}><TermsConditionsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``} id="scrollToDisclaimer"><Disclaimer /></div>
                </div>

                <div className="w-full sticky bottom-24 z-50">
                    {catalogue && <PriceDetailsTemplate handleAddToCart={handleAddToCart} paymentType={currentCartItem.paymentType} moq={moq} handleQuantityChange={handleQuantityChange} minimumPrice={catalogue.minimumPrice} maximumPrice={catalogue.maximumPrice} quantity={currentCartItem.quantity} gst={18} selectedUpc={currentCartItem?.upc} warehouse={currentCartItem.warehouse} uom={(currentCartItem?.warehouse?.uom)?.toString() || ""} catalogueType={CATALOGUE_TYPE_STATES.CURRENT_INVENTORY} catalogue={catalogue} productCombination={productCombination}/>}
                </div>

            </div>
        </div>
    );
}

export default CatalogueDetails;
