import React from 'react'
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { MachinePayload } from '../../../../../services/useVASMasterCategoryService';
import { makeRoute } from '../../../../../utils/helper';
import { SERVICE_STATUS_OPTIONS, VALUE_ADDED_SERVICE_ROUTES } from '../../../../../utils/constant';
import Button from '../../../../atoms/Button/Button';
import { Attribute, SubAttribute } from '../../../../../services/useAttributeServiceV2';
import StatusV2 from '../../../../atoms/Status/StatusV2';

interface MachineViewTemplateProps {
    mode: string,
    formik: FormikProps<MachinePayload>,
    onBack: () => void
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    property: {
        color: theme.palette.textV2.tertiary.tertiary700,
        fontSize:"14px"
    },
    image: {
        width: "141px",
        height: "159px"
    },
    value: {
        color: theme.palette.textV2.primary.primary950
    },
    number: {
        backgroundColor: theme.palette.backgroundV2.primary.primary100,
        color: theme.palette.textV2.primary.primary800,
        width: "54px",
        height: "54px"
    },
    mainValue:{
        color: theme.palette.textV2.primary.primary400,
    }
}));

const MachineViewTemplate: React.FC<MachineViewTemplateProps> = ({ mode, onBack, formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const params = useParams();

    const handleActionRoute = (id: number) => () => {
        navigate(makeRoute(VALUE_ADDED_SERVICE_ROUTES.MACHINES_UPDATE, { params: { id: id } }));
    };

    return (
        <form className="grid gap-y-6" >
            <div className='flex gap-1'>
                <div className={`${classes.mainHeading} text-lg font-medium my-auto`}>View Machine:</div>
                <div className={`${classes.mainValue} text-lg font-medium my-auto`}>{formik.values?.machineName}</div>
            </div>

            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Machine Overview</div>
                    <div className='flex justify-between gap-2'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} w-max`}>Machine Name</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.machineName}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} w-max`}>Brand/Manufacturer of Machine</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.machineVendor}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} w-max`}>Model Number</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.modelNumber}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} w-max`}>Warehouse Location</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.warehouse?.label}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} w-max`}>Machine Type</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.machineType}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} w-max`}>Year of Manufacture</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.manufactureYear}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} w-max`}>Operational Speed</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.operationalSpeed}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>USC and Service Category</div>
                    <div className='grid grid-cols-5'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Service Category</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.serviceCategory?.label}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Capability</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.usc?.label}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Attributes Linked with Selected USC</div>
                    {
                        formik.values?.attributes &&
                        <div className={`grid gap-y-4`} >
                            {
                                formik.values?.attributes?.map((attribute: Attribute, key: any) => (
                                    <div className={`grid gap-y-4 p-4 rounded-xl ${classes.border}`} key={attribute.id}>
                                        <div className={`${classes.heading} text-base font-medium`}>{attribute.name}</div>
                                        <div className='grid grid-cols-2 gap-y-4'>
                                            {
                                                attribute.subAttributes.map((subAttribute: SubAttribute, index: any) => (
                                                    <div className='flex gap-x-6'>
                                                        <div className={`${classes.number} rounded-md text-lg font-semibold my-auto flex justify-center items-center`}>{index + 1}</div>
                                                        <div className={`grid gap-y-1`}>
                                                            <div className={`${classes.property}`}>Thickness Range</div>
                                                            <div className={`${classes.heading} text-base font-medium`}>{(+subAttribute.minValue).toFixed(2)}-{(+subAttribute.maxValue).toFixed(2)} {attribute?.uom}</div>
                                                        </div>
                                                        <div className={`grid gap-y-1`}>
                                                            <div className={`${classes.property}`}>- Tolerance</div>
                                                            <div className={`${classes.heading} text-base font-medium`}> {formik.values?.subAttributeIds?.[subAttribute.id]?.minTolerance===0 ? formik.values?.subAttributeIds?.[subAttribute.id]?.minTolerance : formik.values?.subAttributeIds?.[subAttribute.id]?.minTolerance.toFixed(2)} {attribute?.uom}</div>
                                                        </div>
                                                        <div className={`grid gap-y-1`}>
                                                            <div className={`${classes.property}`}>+ Tolerance</div>
                                                            <div className={`${classes.heading} text-base font-medium`}> {formik.values?.subAttributeIds?.[subAttribute.id]?.maxTolerance===0 ? formik.values?.subAttributeIds?.[subAttribute.id]?.maxTolerance : formik.values?.subAttributeIds?.[subAttribute.id]?.maxTolerance.toFixed(2)} {attribute?.uom}</div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))}
                        </div>
                    }
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Equipment</div>
                    <div className='grid grid-cols-5'>
                        {formik.values?.equipmentNames?.map((name: string, index: number) => (
                            <div key={index} className="grid gap-y-1">
                                <div className={`${classes.property}`}>{index + 1}. Equipment Name</div>
                                <div className={`${classes.heading} text-base font-medium`}>{name}</div>
                            </div>
                        ))}
                    </div>
                    <div className="grid gap-y-1">
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Description</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.equipmentDescription}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Other Details</div>
                    <div className='grid grid-cols-5'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Material Handle</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.materialHandle}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Quality Standard</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.qualityStandard}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Material Grade</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.materialGrade}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Serviceability</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.serviceability}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Order Quantity (Min-Max)</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.minOrderQuantity} - {formik.values?.maxOrderQuantity} MT</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Service Price</div>
                    <div className='grid grid-cols-5'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Normal Service Charges/MT</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.serviceCharge?.normalServiceCharge}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Minimum Service Charges/MT</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.serviceCharge?.minimumServiceCharge}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Maximum Service Charges/MT</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.serviceCharge?.maximumServiceCharge}</div>
                        </div>

                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Machine Description</div>
                    <div className="grid gap-y-1">
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Description</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.machineDescription}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Machine Images and Videos</div>
                    <div className="flex gap-x-4">
                        {formik?.values?.images?.map((image: any, index: number) => (
                            <img className={`${classes.image} rounded-lg`} key={index} src={image.path} alt="machineImage" />
                        ))}
                    </div>
                </div>

                <StatusV2 heading="Machine Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" mode={mode} />
            </div>

            <div className="flex justify-end gap-4 mt-2">
                <Button variant="tertiaryContained" label='Back' onClick={onBack} />
                <Button onClick={handleActionRoute(Number(params.id))} variant='primaryContained' label={"Edit"} />
            </div>
        </form>
    )
}

export default MachineViewTemplate