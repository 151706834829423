import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from '../../../../../hooks/useSnackBar';
import { MachinePayload, useVASMasterCategoryService } from '../../../../../services/useVASMasterCategoryService';
import { useFileService } from '../../../../../services/useFileService';
import { DOCUMENT_RESOURCE_TYPE, GENERIC_EXCEPTION_CODE, MODE, STATUS, VALUE_ADDED_SERVICE_ROUTES } from '../../../../../utils/constant';
import { makeRoute } from '../../../../../utils/helper';
import { HTTP_STATUS } from '../../../../../utils/types';
import MachineFormTemplate from '../../../../templates/DashBoard/ValueAddedService/Machine/MachineForm.template';
import MachineReviewTemplate from '../../../../templates/DashBoard/ValueAddedService/Machine/MachineReviewTemplate';
import { useAuthenticatedUser } from '../../../../../hooks/useAuthenticatedUser';

const validationSchema = Yup.object().shape({
    serviceCategory: Yup.object().shape({
        id: Yup.number().required('Service Category is required'),
        label: Yup.string().required('Service Category is required')
    }).required('Service Category is required'),
    usc: Yup.object().shape({
        id: Yup.number().required('USC is required'),
        label: Yup.string().required('USC is required')
    }).required('USC is required'),
    uscId: Yup.number().required('USC is required'),
    machineName: Yup.string().max(30, "Machine Name cannot be more than 30 characters").required("Machine Name is required"),
    machineVendor: Yup.string().max(30, "Brand/Manufacturer cannot be more than 30 characters").required("Brand/Manufacturer is required"),
    modelNumber: Yup.string().max(30, "Model Number cannot be more than 30 characters").required("Model Number is required"),
    machineType: Yup.string().max(30, "Machine Type cannot be more than 30 characters").required("Machine Type is required"),
    manufactureYear: Yup.number().min(1000, 'Enter a valid Year Of Manufacture').max(9999, 'Enter a valid Year Of Manufacture').required('Year Of Manufacture is required'),
    operationalSpeed: Yup.number().moreThan(0, "Operational Speed should be more than zero").required("Operational Speed is required"),
    equipmentNames: Yup.array().of(Yup.string()).min(1, 'At least one Equipment Name Requirement is required').required('Equipment Name is required'),
    equipmentDescription: Yup.string().max(500, "Equipment Description cannot be more than 500 characters").required("Equipment Description is required"),
    materialHandle: Yup.string().max(30, "Material Type cannot be more than 30 characters").required("Material Type is required"),
    qualityStandard: Yup.string().max(30, "Quality Standard cannot be more than 30 characters").required("Quality Standard is required"),
    materialGrade: Yup.string().max(30, "Material Grade cannot be more than 30 characters").required("Material Grade is required"),
    serviceability: Yup.string().max(30, "Serviceability cannot be more than 30 characters").required("Serviceability is required"),
    minOrderQuantity: Yup.number().moreThan(0, "Minimum Order Quantity should be more than zero").required("Minimum Order Quantity is required"),
    maxOrderQuantity: Yup.number().moreThan(0, "Maximum Order Quantity should be more than zero").required("Maximum Order Quantity Speed is required"),
    serviceCharge: Yup.object().shape({
        normalServiceCharge: Yup.number().moreThan(0, "Normal Service Charges/MT should be more than zero").required("Normal Service Charges/MT is required"),
        minimumServiceCharge: Yup.number().moreThan(0, "Minimum Service Charges/MT should be more than zero").required("Minimum Service Charges/MT is required"),
        maximumServiceCharge: Yup.number().moreThan(0, "Maximum Service Charges/MT should be more than zero").required("Maximum Service Charges/MT is required"),
    }),
    machineDescription: Yup.string().max(500, "Machine Description cannot be more than 500 characters").required("Machine Description is required"),
    warehouse: Yup.object().shape({
        id: Yup.number().required('Warehouse is required'),
        label: Yup.string().required('Warehouse is required')
    }).required('Warehouse is required'),
});

export enum MACHINE_FORM_STATE {
	MACHINE_DETAILS = 1,
	MACHINE_REVIEW = 2
}

const MachineCreatePage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { user } = useAuthenticatedUser();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const fileService = useFileService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [formState, setFormState] = useState<MACHINE_FORM_STATE>(1);

    const onBack = () => {
        navigate(makeRoute(VALUE_ADDED_SERVICE_ROUTES.VALUE_ADDED_SERVICE_LIST, { query: { type: 'MACHINE' } }));
    }

    const uploadImage = async (id: number) => {
        try {
            const result = [];
            const fileToUpload = formik.values.images.filter(image => image instanceof File);
            for (let image of fileToUpload) {
                if (image instanceof File) {
                    const presignedUrlResponse = await fileService.generatePresignedUrl(image.name, DOCUMENT_RESOURCE_TYPE.VAS_MACHINE, id);
                    if (presignedUrlResponse && presignedUrlResponse.data && presignedUrlResponse.data.data && presignedUrlResponse.data.data.presignedUrl) {
                        const presignedUrl = presignedUrlResponse.data.data.presignedUrl;
                        const imageUploadResponse = await fileService.uploadImageUsingPreSignedUrls(image, presignedUrl);
                        if (imageUploadResponse.status === HTTP_STATUS.OK) {
                            let res = await fileService.createFile({
                                resourceType: "VAS_MACHINES",
                                resourceId: id,
                                path: `vas_machines/${id}/${image.name.split(" ").join("_")}`,
                                mimeType: image.type,
                                // metaData: {
                                //     documentName: image.name,
                                //     documentType: null,
                                //     documentCategory: "IMAGE"
                                //   },
                            })
                            if (res?.status === HTTP_STATUS.OK) {
                                result.push(true)
                            }
                            else {
                                result.push(false)
                                showSnackbar("error", `Failed to upload image: ${image.name}`)
                            }
                        } else {
                            result.push(false)
                            showSnackbar("error", `Failed to upload image: ${image.name}`)
                        }
                    } else {
                        result.push(false)
                        showSnackbar("error", 'Failed to generate presigned URL')
                    }
                } else {
                    result.push(false)
                    showSnackbar("success", "Machine Service created successfully");
                }
            }
            if (!result.includes(false)) {
                setIsLoading(false);
                showSnackbar("success", "Machine Service uploaded successfully");
            } else {
                showSnackbar("error", 'File to upload Images:')
            }
        } catch (error) {
            setIsLoading(false);
            showSnackbar("error", 'File upload failed:')
        }
    }

    const createMachine = async (machine: MachinePayload) => {
         const machineRequestBody: any = {
            uscId: machine.uscId,
            subAttributeIds: machine.subAttributeIds,
            machineName: machine.machineName,
            machineVendor: machine.machineVendor,
            modelNumber: machine.modelNumber,
            location: null,
            machineType: machine.machineType,
            manufactureYear: machine.manufactureYear,
            operationalSpeed: machine.operationalSpeed,
            equipmentName: machine.equipmentName,
            equipmentNames: machine.equipmentNames,
            equipmentDescription: machine.equipmentDescription,
            materialHandle: machine.materialHandle,
            qualityStandard: machine.qualityStandard,
            materialGrade: machine.materialGrade,
            serviceability: machine.serviceability,
            minOrderQuantity: machine.minOrderQuantity,
            maxOrderQuantity: machine.maxOrderQuantity,
            serviceCharge: {
              normalServiceCharge: machine.serviceCharge.normalServiceCharge,
              minimumServiceCharge: machine.serviceCharge.minimumServiceCharge,
              maximumServiceCharge: machine.serviceCharge.maximumServiceCharge,
            },
            machineDescription: machine.machineDescription,
            status: machine.status ?? STATUS.ACTIVE,
            warehouseId: machine.warehouse.id,
            vendorId: user?.businessId,
         }

        vasMasterCategoryService.createMachine(machineRequestBody, {})
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    uploadImage(res.data.data.id)
                    showSnackbar('success', "New Machine Created");
                    onBack();
                } else if (res.data.exceptionCode === GENERIC_EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Machine already exists. Please try again");
                }
            }).catch((error: any) => {
                showSnackbar('error', "Failed to create Machine");
            })
    }

    const formik = useFormik<MachinePayload>({
        initialValues: {
            serviceCategory: {
                label: "",
                id: ""
            },
            usc: {
                label: "",
                id: ""
            },
            uscId: null,
            subAttributeIds: {},
            machineName: "",
            machineVendor: "",
            modelNumber: "",
            location: "",
            machineType: "",
            manufactureYear: null,
            operationalSpeed: "",
            equipmentName: "",
            equipmentNames: [],
            equipmentDescription: "",
            materialHandle: "",
            qualityStandard: "",
            materialGrade: "",
            serviceability: "",
            minOrderQuantity: null,
            maxOrderQuantity: null,
            serviceCharge: {
              normalServiceCharge: null,
              minimumServiceCharge: null,
              maximumServiceCharge: null,
            },
            machineDescription: "",
            status: STATUS.ACTIVE,
            attributes: [],
            images: [],
            imageListToDelete: [],
            warehouse: {
                label: "",
                id: ""
            },
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            createMachine({ ...values, status: values.status })
        }
    })

    const setFormStateTo = (formState: MACHINE_FORM_STATE) => () => {
        setFormState(formState)
    }

    const activeFormStep = useMemo(() => {
        const props = { mode: MODE.ADD, onBack, formik, setFormStateTo }
        switch (formState) {
            case MACHINE_FORM_STATE.MACHINE_REVIEW: return <MachineReviewTemplate mode={MODE.ADD} onBack={onBack} formik={formik} setFormStateTo={setFormStateTo}/>;
            default: return <MachineFormTemplate mode={MODE.ADD} onBack={onBack} formik={formik} setFormStateTo={setFormStateTo}/>;
        }
    }, [formState, formik])

    return (
        <div>
            {SnackBarComponent}
            {activeFormStep}
        </div>
    )
}

export default MachineCreatePage