import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export interface ISellerAccountCreationRequestBody {
    vendorType: string
}
export const BUSINESS_PROFILE_URLS = {
    GET: "/business-profile",
    UPDATE: "/business-profile/section/:section",
    UPDATE_SELLER: "/business-profile/vendor/:id",
    GET_CIN: "/cin-details",
}

export const useBusinessProfileService = () => {
    const { user } = useAuthenticatedUser();

    const getBusinessProfile = () => {
        return request(API_METHOD.GET, BUSINESS_PROFILE_URLS.GET, user, null);
    }

    const updateBusinessProfile = (data: any, section: string) => {
        return request(API_METHOD.PUT, replaceUrlParams(BUSINESS_PROFILE_URLS.UPDATE, { section }), user, data);
    }

    const updateBusinessSellerProfile = (data: ISellerAccountCreationRequestBody, id: number) => {
        return request(API_METHOD.PUT, replaceUrlParams(BUSINESS_PROFILE_URLS.UPDATE_SELLER, { id }), user, data);
    }

    const getCinDetails = (data: any) => {
        return request(API_METHOD.POST, BUSINESS_PROFILE_URLS.GET_CIN, user, data);
    }
    
    return {
        getBusinessProfile, updateBusinessProfile, getCinDetails, updateBusinessSellerProfile
    }
}