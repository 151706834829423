import React, { useState } from 'react';
import { CATALOGUE_TYPE, IPagination } from '../../../utils/types';
import TableV2, { ColumnType } from '../../organisms/Table/TableV2';
import ResourceStatusV2 from '../../atoms/ResourceStatusV2/ResourceStatusV2';
import Button from '../../atoms/Button/Button';
import { enumToString } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { ICataloguesList } from './VendorCatalogueTableView.template';
import { VENDOR_CATALOGUE_ROUTES } from '../../../utils/constant';
import moment from 'moment';

interface IVendorCataloguesListingTemplateProps {
    cataloguesList: ICataloguesList[];
    pagination: IPagination;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    vendorId: number;
}


const VendorCataloguesListingTemplate: React.FC<IVendorCataloguesListingTemplateProps> = ({ cataloguesList, pagination, handlePaginationChange, handleRowsPerPageChange, vendorId }) => {
    const navigate = useNavigate();
    const Action = (id: number) => (
        <div>
            <Button
                variant="tertiaryText"
                label={"Edit"}
                size='small'
                onClick={() => {
                    navigate(`${VENDOR_CATALOGUE_ROUTES.EDIT_CATALOGUE}/${id}`);
                }}
            />
            <Button
                variant="tertiaryText"
                label={"View"}
                size='small'
                onClick={() => {
                    navigate(`${VENDOR_CATALOGUE_ROUTES.VIEW_CATALOGUE}/${id}`);
                }}
            />
        </div>
    );


    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.page,
            isVisible: true,
            limit: pagination.size,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Catalog ID", key: "id", type: "string" as ColumnType, props: { className: '' } },
            { label: "Catalog Name", key: "catalogueName", type: "string" as ColumnType, props: { className: 'flex text-left ml-3.5' } },
            { label: "Catalog Type", key: "catalogueType", type: "string" as ColumnType, props: { className: '' } },
            { label: "No of Products", key: "productCount", type: "number" as ColumnType, props: { className: '' } },
            // { label: "Sales Price", key: "reserveSalesPrice", type: "string" as ColumnType, props: { className: '' } },
            { label: "Created On", key: "createdAt", type: "string" as ColumnType, props: { className: 'w-16 m-auto' } },
            { label: "Last Update", key: "updatedAt", type: "string" as ColumnType, props: { className: 'w-16 m-auto' } },
            { label: "Created By", key: "createdBy", type: "string" as ColumnType, props: { className: 'w-16 m-auto' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
        ],
    };

    const records = cataloguesList.map((catalogueList, index) => [
        pagination.page * pagination.size + index + 1,
        catalogueList.id,
        catalogueList.catalogueName,
        CATALOGUE_TYPE[catalogueList.catalogueType as keyof typeof CATALOGUE_TYPE],
        catalogueList.productCount,
        // catalogueList.reserveSalesPrice ?? "-",
        moment(catalogueList.createdAt).format("DD-MM-YYYY HH:mm"),
        moment(catalogueList.updatedAt).format("DD-MM-YYYY HH:mm"),
        catalogueList.createdBy,
        enumToString(catalogueList.status),
        Action(catalogueList.id)
    ]);

    return (
        <div>
            <TableV2 schema={schema} records={records} />
        </div>
    );
};

export default VendorCataloguesListingTemplate;
