import React, { useState } from 'react'
import { createUseStyles } from 'react-jss';
import dropDownIcon from "../../../../../assets/icons/dropDownFilledIcon.svg";
import dropUpIcon from "../../../../../assets/icons/dropUpFilledIcon.svg";
import crossIcon from "../../../../../assets/icons/crossIconFilled.svg";

interface DropDownProps {
    handleDelete: (index: number) => void;
    heading: string;
    selectedValue: any[];
}

const useStyles = createUseStyles((theme: any) => ({
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        borderTop: "none"
    },
    mainContainer: {
        width: "367px",
    },
    container: {
        backgroundColor: theme.palette.backgroundV2.primary.primary100,
    },
    heading: {
        color: theme.palette.textV2.success.success500,
        lineHeight: "16.1px"
    },
    option: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 16px',
        color: theme.palette.textV2.tertiary.tertiary800,
    },
    closeIcon: {
        cursor: 'pointer',
    }
}));

const DropDown: React.FC<DropDownProps> = ({ heading, handleDelete, selectedValue }) => {
    const classes = useStyles();
    const [showAll, setShowAll] = useState(false);

    const handleClick = () => {
        setShowAll(!showAll);
    };

    return (
        <div className={`${classes.mainContainer}`}>
            <div className={`${classes.container} flex justify-between px-4 py-2  rounded-2xl`}>
                <div className={`${classes.heading} text-sm font-medium my-auto`}>{heading} ({selectedValue.length})</div>
                <div className={` cursor-pointer`} onClick={handleClick} >
                    {showAll ? <img src={dropUpIcon}  alt='dropUpIcon' /> : <img src={dropDownIcon} alt='dropDownIcon' />}
                </div>
            </div>

            {showAll && (
                <div className={`${classes.border} rounded-2xl grid gap-y-3 pt-7 pb-1.5 -mt-6`}>
                    {selectedValue.map((equipment: string, index: number) => (
                        <div key={index} className={classes.option}>
                            <div className='text-xs font-medium'>{equipment}</div>
                            <img src={crossIcon} alt='crossIcon' className={classes.closeIcon} onClick={() => handleDelete(index)} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default DropDown;