import React, { useState, ReactNode, ReactElement, useMemo, useCallback } from 'react';
import successIcon from "../assets/icons/toastorCheck.svg";
import failedIcon from "../assets/icons/toastorCross.svg";


type SnackbarType = 'success' | 'error' | 'info' | 'warning';

interface SnackbarState {
  isActive: boolean;
  type: SnackbarType;
  message: string;
}

interface SnackbarContextProps {
  showSnackbar: (type: 'success' | 'error' | 'info' | 'warning', message: string, duration?: number) => void;
  hideSnackbar: () => void;
  SnackBarComponent: ReactNode;
}

interface SnackbarProviderProps {
  children: ReactElement
}

export const SnackbarContext = React.createContext<SnackbarContextProps | undefined>(undefined);

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [snackBar, setSnackBar] = useState<SnackbarState>({
    isActive: false,
    type: 'success',
    message: '',
  });

  const showSnackbar = useCallback((type: 'success' | 'error' | 'info' | 'warning', message: string, duration: number = 2000) => {
    setSnackBar({
      isActive: true,
      type,
      message,
    });

    setTimeout(() => {
      setSnackBar((prev) => ({
        ...prev,
        isActive: false,
      }));
    }, duration);
  }, []);

  const hideSnackbar = useCallback(() => {
    setSnackBar((prev) => ({
      ...prev,
      isActive: false,
    }));
  }, [setSnackBar]);

  const getColor = (type: SnackbarType) => {
    switch (type) {
      case 'success':
        return {background: '#25A74A', border: '#1C7D37'};
      case 'error':
        return {background: '#DA1E27', border: '#861318'};
      case 'info':
        return {background: '#fd6202', border: '#fd6202'};
      case 'warning':
        return {background: 'orange', border: 'orange'};
      default:
        return {background: 'grey', border: 'grey'};
    }
  };
  
  const getIcon = (type: SnackbarType) => {
    switch (type) {
      case 'success':
        return successIcon;
      case 'error': 
        return failedIcon;
      case 'info':
        return ;
      case 'warning':
        return successIcon;
      default:
        return successIcon;
    }
  };

  const SnackBarComponent = useMemo(() => {
    return (
      <>
        {snackBar.isActive && !!snackBar.message && <div id='snackBar'
          className='fixed top-[60px] right-[20px] text-white p-4 flex gap-4 items-center text-lg font-semibold min-w-[277px] h-[59px]'
          style={{
            borderLeft: '6px solid',
            borderColor: getColor(snackBar.type).border,
            backgroundColor: getColor(snackBar.type).background,
            color: 'white',
            padding: '16px',
            borderRadius: '4px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
          }}>
          <div><img src={getIcon(snackBar.type)} alt="" /></div>
          <div>{snackBar.message}</div>
        </div>}
      </>
    )
  }, [snackBar, hideSnackbar]);

  const providerValue = useMemo(() => {
    return {
      showSnackbar, hideSnackbar, SnackBarComponent
    }
  }, [showSnackbar, hideSnackbar, SnackBarComponent])

  return (
    <SnackbarContext.Provider value={providerValue}>
      {children}
    </SnackbarContext.Provider>
  );
};