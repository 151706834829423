import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import TableV2, { ColumnType } from '../../../organisms/Table/TableV2';
import ViewEyeIcon from '../../../../assets/icons/outlineView.svg';
import { CATEGORY_TYPE, HTTP_STATUS, IPagination } from '../../../../utils/types';
import { PRODUCT_SECTION, STATUS_OPTION, initialPaginationValues, statusOptions } from '../../../../utils/constant';
import { IInventoryManagementProps } from '../../../pages/DashBoard/InventoryManagement.page';
import { useNavigate } from 'react-router-dom';
import { useVendorInventoryService } from '../../../../services/useVendorInventoryService';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import TextFieldV2 from '../../../atoms/Input/TextFieldV2';
import Search from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import FilterChipV2, { IMultiSelectOption } from '../../../molecules/FilterChipV2/FilterChipV2';
import Button from '../../../atoms/Button/Button';
import SelectV2 from '../../../atoms/Select/SelectV2';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import ResourceStatus from '../../../atoms/ResourceStatus/ResourceStatus';
import { capitalizeFirstLetter, enumToString } from '../../../../utils/helper';
import ResourceStatusV2 from '../../../atoms/ResourceStatusV2/ResourceStatusV2';
import { IProductsParams, usePostProductService } from '../../../../services/usePostProductService';
import {  useAdminMasterService } from '../../../../services/useAdminMasterService';
import { ICategory } from '../../Home/ProductRequirementForm.template';
import { ICataloguesListingFilter } from '../../VendorManagement/ProductCataloguesTableView.template';
import { IProductSearchParams, useGenericSearchService } from '../../../../services/useGenericSearchService';

export interface IVendorInventory {
  availableStock: number;
  numberOfUsn: number;
  productName: string;
  productUpcBrandId: number;
  skuCode: string;
  status: string;
  vendorProductId: number;
}

export interface ISkuCodes {
  id:  number;
  skuCode: string;
  productCode: string;
  productId: number;
  }

const useStyles = createUseStyles((theme: any) => ({
  heading: {
    color: theme.palette.borderV2.primary.primary900,
  },
  searchIcon: {
    color: theme.palette.icon.primaryLight
  },
  textField: {
    padding: "14px 12px"
},
}));

const initialCataloguesListingFiltersValues: ICataloguesListingFilter = {
  status: "",
  search: "",
  category: "",
  classType: "STANDARD",
  productIdString: ""
}


const InventoryMangementTemplate: React.FC<IInventoryManagementProps> = ({ activeTab, stats }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
  const [vendorInventories, setVendorInventoriesTo] = useState<IVendorInventory[]>([]);
  const [cataloguesFilters, setcataloguesFiltersTo] = useState<ICataloguesListingFilter>(initialCataloguesListingFiltersValues);
  const vendorInventoryService = useVendorInventoryService();
  const genericSearchService = useGenericSearchService();
  const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);
  const [skuCodesData, setSkuCodesData] = useState<ISkuCodes[] | null>([]);
  const [skuSearchTerm, setSkuSearchTerm] = useState<string>('');
  const [category, setCategory] = useState<IMultiSelectOption[]>([]);
  const [skuCode, setSkuCode] = useState<IMultiSelectOption[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const [status, setStatus] = useState<IMultiSelectOption[]>([]);
  const { user } = useAuthenticatedUser();

  const vendorId = user?.businessId!!;
  const productService = usePostProductService();
  const adminService = useAdminMasterService();



  const loadProductCategory = () => {
    adminService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: searchTerm ?? '', status: 'ACTIVE' })
        .then(res => {
            if (res?.status === HTTP_STATUS.OK)
                setProductCategoryData(res?.data?.data?.content)
        }).catch((error) => {
            console.error("Error Fetching Category: ", error);
        })
}

const loadSkuCodes = () => {
    let params: IProductSearchParams = {
      page: 0,
      size: 10,
      sort: 'createdAt,desc',
      sectionType: PRODUCT_SECTION.BRAND_UPC,
      skuCode: skuSearchTerm
  }
  genericSearchService.searchProduct(params, {})
        .then(res => {
            if (res?.status === HTTP_STATUS.OK) {
                setSkuCodesData(res?.data?.data?.content); 
            }
        })
        .catch((error) => {
            console.error("Error Fetching SKU codes: ", error);
        });
};

const transformedProductCategoryData: IMultiSelectOption[] = productCategoryData
    ? productCategoryData.map(category => ({
        label: category.name,
        value: category.id
    }))
    : [];

const transformedSkuCodeData: IMultiSelectOption[] = skuCodesData
    ? skuCodesData.map(skuCode => ({
        label: skuCode.productCode,
        value: skuCode.productId
    }))
    : [];


  const getVedorInventories = async () => {
    if (user?.businessId) {
      try {
        const params: IProductsParams = {
          page: pagination.page,
          size: pagination.size,
          status: cataloguesFilters.status,
          category: cataloguesFilters.category,
          search: cataloguesFilters.search,
          productIdString: cataloguesFilters.productIdString,
          classType: activeTab ?? initialCataloguesListingFiltersValues.classType,
          sort: 'createdAt,desc',
      };
    
        const vedorInventoriesResponse = await vendorInventoryService.getAllVendorInventories(user?.businessId, params);
        if (vedorInventoriesResponse.status === HTTP_STATUS.OK) {
          const vendorInventoryData = vedorInventoriesResponse?.data?.data?.content;
          setVendorInventoriesTo(vendorInventoryData);
          setPagination((prevPagination: IPagination) => ({
            ...prevPagination,
            totalRecords: vedorInventoriesResponse?.data?.data?.totalElements,
        }));
        }
      } catch (error) {
        showSnackbar('error', 'Business Profile fetch failed');
      } 
    }
  
  };

  useEffect(() => {
    getVedorInventories();
  }, [activeTab, pagination.size, pagination.page, cataloguesFilters]);

  useEffect(() => {
    loadProductCategory();
}, [searchTerm]);

useEffect(() => {
    loadSkuCodes();
}, [skuSearchTerm]);

  const handleCategorySaveClick = (category: IMultiSelectOption[]) => {
    const valueString = category.map(item => item.value).join(',');
    handleFiltersChange("category", valueString);
};

const handleStatusSaveClick = (status: IMultiSelectOption[]) => {
  const valueString = status.length > 0 ? status[0].value : '';
  handleFiltersChange("status", valueString);
};

const handleCodeSaveClick = (skuCodes: IMultiSelectOption[]) => {
  const valueString = skuCodes.map(item => item.value).join(',');
  handleFiltersChange("productIdString", valueString);
};

  const handleSelectionChangeCategory = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
    setCategory(selected);
    clearWithCrossIcon && handleCategorySaveClick(selected);
};

const handleSelectionChangeCode = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
  setSkuCode(selected);
  clearWithCrossIcon && handleCodeSaveClick(selected);
};

const handleSelectionChangeStatus = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
  setStatus(selected);
  clearWithCrossIcon && handleStatusSaveClick(selected);
};

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPagination((prevPagination: IPagination) => ({
      ...prevPagination,
      page: newPage
    }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPagination((prevPagination: IPagination) => ({
      ...prevPagination,
      page: 0,
      size: parseInt(event.target.value, 10)
    }));
  };
  const handleAction = (id: number) => {
    navigate(`/dashboard/inventory-management/${id}`);
  }

  const handleFiltersChange = (name: string, value: any) => {
    setPagination({ ...pagination, page: 0 });
    setcataloguesFiltersTo({ ...cataloguesFilters, [name]: value ?? "" });
  };



  const handleClearClick = (filterType: string) => {
    switch (filterType) {
        case 'status':
            setStatus([]);
            handleFiltersChange("status", '');
            break;
        case 'category':
            setCategory([]);
            handleFiltersChange("category", '');
            break;
        case 'productIdString':
            setSkuCode([]);
            handleFiltersChange("productIdString", '');
            break;
    }
};

  const Action = (id: number) => (
    <div className='flex items-center justify-center gap-x-3'>
      <Button
            variant="tertiaryText"
            label={"View"}
            size='small'
            onClick={() => handleAction(id)}
        />
    </div>
  )

  const getTableColumns = () => [
    { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
    { label: "SKU Code", key: "skuCode", type: "customm" as ColumnType, props: { className: '' } },
    { label: "Product Name", key: "productName", type: "custom" as ColumnType, props: { className: '' } },
    { label: "No of USNs", key: "numberOfUsn", type: "custom" as ColumnType, props: { className: '' } },
    { label: "Available Stock (MT)", key: "availableStock", type: "custom" as ColumnType, props: { className: '' } },
    { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
    { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
  ]

  const getRecords = () => vendorInventories.map((vendorInventory: IVendorInventory, index) => [
    pagination.page * pagination.size + index + 1,
    vendorInventory.skuCode ? vendorInventory.skuCode : "NA",
    vendorInventory.productName ? vendorInventory.productName : "NA",
    vendorInventory.numberOfUsn,
    vendorInventory.availableStock,
    enumToString(vendorInventory.status),
    Action(vendorInventory.vendorProductId)
  ])
  const getSchema = () => ({
    id: "1",
    pagination: {
      total: pagination.totalRecords,
      currentPage: pagination.page,
      isVisible: true,
      limit: pagination.size,
      handleChangePage: handleChangePage,
      handleChangeRowsPerPage: handleChangeRowsPerPage
    },
    columns: getTableColumns() ?? []
  })

  return (
    <div className='mt-8'>
      
        <div className={`${classes.heading} text-lg font-semibold w-full`}>{capitalizeFirstLetter(activeTab)} </div>
        <div className='flex justify-between items-center gap-2 mb-4'>
                <FilterChipV2
                    options={statusOptions}
                    label=" "
                    value={status}
                    onchange={handleSelectionChangeStatus}
                    placeholder="Status"
                    ClearButtonComponent={Button}
                    buttonLabel={"Clear all"}
                    buttonOnClick={() => handleClearClick('status')}
                    SaveButton={Button}
                    saveButtonLabel='Apply'
                    saveButtonOnClick={() => handleStatusSaveClick(status)}
                    isSingleSelect={true}
                    minWidth='180px'
                />

                 <FilterChipV2
                    options={transformedSkuCodeData}
                    label=" "
                    value={skuCode}
                    onchange={handleSelectionChangeCode}
                    placeholder="SKU Code"
                    ClearButtonComponent={Button}
                    buttonLabel={"Clear all"}
                    buttonOnClick={() => handleClearClick('productIdString')}
                    SaveButton={Button}
                    saveButtonLabel='Apply'
                    saveButtonOnClick={() => handleCodeSaveClick(skuCode)}
                    textFieldPlaceholder='Search...'
                    searchTerm={skuSearchTerm}
                    setSearchTerm={setSkuSearchTerm}
                    minWidth='200px'
                />

                <FilterChipV2
                    options={transformedProductCategoryData}
                    label=" "
                    value={category}
                    onchange={handleSelectionChangeCategory}
                    placeholder="Product Category"
                    ClearButtonComponent={Button}
                    buttonLabel={"Clear all"}
                    buttonOnClick={() => handleClearClick('category')}
                    SaveButton={Button}
                    saveButtonLabel='Apply'
                    saveButtonOnClick={() => handleCategorySaveClick(category)}
                    textFieldPlaceholder='Search...'
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    isSingleSelect={true}
                    minWidth='200px'
                />
               
                <div className='flex justify-end ml-auto'>
                    <TextFieldV2
                        label=''
                        variant="outlined"
                        placeholder="Search..."
                        name='search'
                        className={classes.textField}
                        onChange={(event) => {
                            handleFiltersChange("search", event.target.value)
                        }}
                        InputProps={{
                            className: "px-2 h-11 text-base ",
                            startAdornment: (
                                <InputAdornment position="start" style={{ marginLeft: '16px' }}>
                                    <Search className={classes.searchIcon} />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
            </div>
      <div>
        <TableV2 schema={getSchema()} records={getRecords()} />
      </div>
    </div>
  )
}

export default InventoryMangementTemplate