import React from 'react'
import VendorCatalogueTemplate from '../../templates/VendorCatalogue/VendorCatalogue.template'
import Button from '../../atoms/Button/Button'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { VENDOR_CATALOGUE_ROUTES } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';

const useStyles = createUseStyles((theme: any) => ({
  heading: {
    color: theme.palette.textV2.primary.primary950,
  },
}));

const VendorCataloguePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { showSnackbar, SnackBarComponent } = useSnackbar();

  const handleExportToAvighna = () => {
    showSnackbar('info', 'Coming Soon!');

  }

  const handleAddNewProduct = () => {
    navigate(VENDOR_CATALOGUE_ROUTES.ADD_CATALOGUE)
  }

  return (
    <div>
      <div className={`${classes.heading} text-lg font-medium`}>Catalog Management</div>
      <div className='flex gap-4 justify-end'>
      <Button variant='tertiaryContained' label={"Export to Avighna"} className='!font-normal' onClick={handleExportToAvighna} />
        <Button variant='contained' label={"+Add New Catalog"} className='!font-normal' onClick={handleAddNewProduct} />
      </div>
      <div className='mt-6'>
        <VendorCatalogueTemplate handleAddNewProduct={handleAddNewProduct} />
      </div>
    </div>
  )
}

export default VendorCataloguePage