import { createUseStyles } from "react-jss";
import { DELIVERY_AND_TRANSPORTATION_SECTION } from "../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        display: "flex",
        justifyContent: "space-between",
        columnGap: "64px",
        rowGap: "0px",
    },
    container: {
        // rowGap: "24px",
    },
    imageWrapper: {
        width: "56px",
        height: "28px",
        background: theme.palette.v4.background.secondaryLightAccent,
        borderTopLeftRadius: "38px",
        borderTopRightRadius: "38px",
        border: `1px solid ${theme.palette.v4.border.primaryLightAccent}`,
        borderBottom: 0,
        position: "absolute",
        top: "-42px",
        zIndex: 2,
    },
    description: {
        color: theme.palette.v3.text.secondaryDark,
        borderRadius: "11px",
        border: `1px solid ${theme.palette.v4.border.primaryLightAccent}`,
        padding: "40px 40px 20px 20px",
        // width:"331px !important"
    },
    heading: {
        color: theme.palette.v3.text._secondaryLight
    },
    "@media (max-width: 480px)": {
        mainContainer: {
            display: "flex",
            flexDirection: "column",
            rowGap: "24px",
        },
        container: {
            // rowGap: "12px",
        },
        imageWrapper: {
            width: "56px",
            height: "28px",
            background: theme.palette.v4.background.secondaryLightAccent,
            borderTopLeftRadius: "38px",
            borderTopRightRadius: "38px",
            border: `1px solid ${theme.palette.v4.border.primaryLightAccent}`,
            borderBottom: 0,
            position: "absolute",
            top: "-42px",
            zIndex: 2,
        },
        description: {
            padding: "24px 20px 16px 20px",
        },

    },
}));

const DeliveryDetailsTemplate: React.FC = () => {

    const classes = useStyles();

    return (
        <div className={`${classes.mainContainer}`}>
            {DELIVERY_AND_TRANSPORTATION_SECTION.map(({ text, icon, description }) => (
                <div key={text} className= {`${classes.container} grid flex-1`}>
                    <div className={`${classes.heading} text-lg w-full font-bold`}>{text}</div>
                    <div className="relative mt-10">
                        <div className={`${classes.imageWrapper} w-14 h-14 py-1.5 px-3 ml-4 mt-4`}>
                            <img src={icon} alt="icon" className={`w-8 h-8`} />
                        </div>
                        <p className={`${classes.description} text-lg font-semibold w-max`}>{description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DeliveryDetailsTemplate;
