import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { HTTP_STATUS, VENDOR_PRODUCT_DETAILS_TYPE } from '../../../utils/types';
import Button from '../../atoms/Button/Button';
import ProductViewTemplate from '../DashBoard/Inventory Management/ProductView.template';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { IproductDetails } from './ExistingProductUpdate.template';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import PriceAddView from './PriceAddView.template';
import { CUSTOMER_ROUTES } from '../../../utils/constant';
import { IProductData } from '../DashBoard/Inventory Management/SelectProduct.template';

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
    textHeading3: {
        color: theme.palette.backgroundV2.primary.primary900
    },
    lineColor: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    UsnText: {
        color: theme.palette.backgroundV2.primary.primary500
    },
    viewProduct: {
        color: theme.palette.textV2.primary.primary400,
    },
    radioColor: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
}));
export interface IInventoryAddViewProps {
    warehouseId: any;
    productId: number;
}

const PriceManagementViewTemplate: React.FC = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const [productsData, setProductsData] = useState<IProductData | null>(null);

    const vendorInventoryService = useVendorInventoryService();
    const [warehouseData, setWarehouseData] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const params = useParams();
    const productId = Number(params.id);

    const { user } = useAuthenticatedUser();

    const handleCancel = () => {
        navigate(`${CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_LISTING}`);
    };

    const handleEdit = () => {
        navigate(`${CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_UPDATE}/${productId}`);
    }

    const getVedorProductbyId = async () => {
        if (user?.businessId) {
            try {
                const ProductsResponse = await vendorInventoryService.getDetailedProduct(user?.businessId, productId, {vendorProductDetailsType: VENDOR_PRODUCT_DETAILS_TYPE.VENDOR_PRODUCT_USN_DETAILS} );
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data;
                    setProductsData({
                        id: productData?.id,
                        productId: productData?.productId,
                        skuCode: productData?.standardProductDetails.upcCode,
                        productName: productData?.productUpcTitle,
                        standardProductDetails: productData?.standardProductDetails,
                        vendorProductStockDetails: productData?.vendorProductStockDetails
                    });
                }
            } catch (error) {
                showSnackbar('error', 'Vendor Product fetch failed');
            }
        }
    };
    useEffect(() => {
        getVedorProductbyId();
    }, [user?.businessId]);

    const fetchWarehouse = async () => {
        if (user?.businessId) {
            try {
                const wareHouseResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId });
                if (wareHouseResponse.status === HTTP_STATUS.OK) {
                    const warehouses = wareHouseResponse?.data?.data?.content;
                    setWarehouseData(warehouses);
                }
            } catch (error) {
                showSnackbar('error', 'Warehouse Profile fetch failed');
            }
        }
    };
    useEffect(() => {
        fetchWarehouse();
    }, [user?.businessId]);

    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className=' grid gap-6 '>
                <div className='flex  justify-between'>
                    <div>
                        <text className={`${classes.textHeading} font-medium text-lg`}>View Product : </text>
                        <text className={`${classes.viewProduct} font-medium text-lg`}>{productsData?.skuCode}</text>
                    </div>
                </div>
                <ProductViewTemplate
                    product={productsData}
                    showButton={false}
                    showUsnDetails={true}
                />
                <div className='w-full relative'>
                    <PriceAddView
                        warehouseId={warehouseData}
                        productId={productId}
                    />
                </div>
            </div>
            <div className=' flex justify-end gap-4'>
                <Button
                    variant="tertiaryContained"
                    label="Back"
                    onClick={handleCancel}
                />
                <Button
                    variant="primaryContained"
                    label="Update"
                    onClick={() => handleEdit()}
                />
            </div>
        </div>
    )
}

export default PriceManagementViewTemplate