import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { CUSTOMER_ROUTES } from '../../../utils/constant';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = createUseStyles((theme: any) => ({
    mainContainer:{
        rowGap: "16px",
    },
    container: {
        border: `1px solid ${theme.palette.border._primaryLight}`
    },
    title: {
        color: theme.palette.text.primaryLightAccent
    },
    content: {
        color: theme.palette.text._primaryDarkAccent
    },
    webText: {
        color: theme.palette.v4.text._primaryLight,
        "&:hover": {
            color: `${theme.palette.v4.text._primaryLightAccent}`,
        },
        display: "flex",
        alignItems:"center"
    },
    mobileText: {
       display: "none"
    },
    icon: {
        marginRight: "-16px",
    },
    "@media (max-width: 480px)": {
        mainContainer:{
            rowGap: "12px",
        },
        webText: {
            display: "none"
        },
        mobileText: {
            color: theme.palette.v4.text._primaryLight,
            "&:hover": {
                color: `${theme.palette.v4.text._primaryLightAccent}`,
            },
            display: "flex"
        },

    },
}));

const TermsConditionsTemplate: React.FC = () => {

    const classes = useStyles();

    return (
        <div className={`${classes.mainContainer} grid`}>
            <div className={`${classes.title} font-inter text-lg font-semibold`}>Terms and Conditions</div>
            <div className={`${classes.container} flex flex-col rounded-3xl px-6 py-4 items-start gap-2`}>
                <div className={`${classes.content} font-inter text-base font-normal`}>The actual product may vary from the image shown on this page. We reserve the right to modify product specifications, prices, or availability without prior notice. Please get in touch with us if you have any questions or require further clarification regarding our steel products. Your satisfaction is our priority, and we are here to assist you with any inquiries or concerns.</div>
                <Link to={CUSTOMER_ROUTES.TERMS_AND_CONDITIONS} target="_blank" className={`${classes.webText} pt-2 text-lg font-medium cursor-pointer`}>View Terms and Conditions
                    <NavigateNextIcon className={`${classes.icon} ml-2`} />
                    <NavigateNextIcon />
                </Link>

                <Link to={CUSTOMER_ROUTES.TERMS_AND_CONDITIONS} target="_blank" className={`${classes.mobileText} pt-2 text-lg font-medium cursor-pointer`}>View More
                    <NavigateNextIcon className={`${classes.icon} ml-2`} />
                    <NavigateNextIcon />
                </Link>
            </div>
        </div>
    );
};

export default TermsConditionsTemplate;