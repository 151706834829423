import WhatsAppIcon from "../../../assets/images/whatsapp.svg";
import { Link } from 'react-router-dom';
import { NUMBER_URL } from "../../../utils/constant";
import { createUseStyles } from "react-jss";

interface WhatsappProps{
    isActive?:boolean
}

const useStyles = createUseStyles((theme: any) => ({
    img: {
        height: "48px",
        margin: "0px 8px",
        width: "64px"
    },
    disableLink:{
        pointerEvents:"none"
    },
    "@media (max-width: 480px)": {
        img: {
            height: "44px",
            margin: "0px 12px",
            width: "48px"
        }
    },
}));

const Whatsapp: React.FC<WhatsappProps> = ({isActive}) => {
    const classes = useStyles();
    return (
        <Link className={isActive ? "" : classes.disableLink} to={NUMBER_URL.WHATSAPP} target="_blank" rel="noopener noreferrer" style={{ margin: 0 }}>
            <img src={WhatsAppIcon} alt="icon" className={`${classes.img} cursor-pointer`} />
        </Link>
    )
}

export default Whatsapp;