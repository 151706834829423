import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { IVendorProductPriceForm } from '../../pages/VendorManagement/VendorProductPriceCreate.page';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS, STATUS } from '../../../utils/types';
import { capitalizeFirstLetter } from '../../../utils/helper';
import Tabs, { ITabsSchema } from '../../molecules/Tabs/Tabs';
import PriceInformationTemplate from './PriceInformation.template';
import TabsV2 from '../../molecules/TabsV2/TabsV2';

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
    textHeading2: {
        color: theme.palette.textV2.primary.primary900
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    radioColor: {
        color: theme.palette.textV2.tertiary.tertiary700
    },

    lineColor: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    UsnText: {
        color: theme.palette.backgroundV2.primary.primary500
    },
    titleText: {
        color: theme.palette.v2.text.primaryDark
    },
    container: {
        "& .MuiDialog-paper": {
            width: "1154px !important",
            maxWidth: "1154px !important",
            borderRadius: "12px"
        },
    },
    mainHeading: {
        color: theme.palette.v3.text.primaryLightAccent
    },
    content: {
        color: theme.palette.v3.text.primaryLight
    },
    button: {
        paddingLeft: "89px !important",
        paddingRight: "89px !important",
        color: theme.palette.button.secondaryDark,
    },
    closeIcon: {
        color: theme.palette.v2.text._primaryDark
    },
    headingText: {
        color: theme.palette.backgroundV2.primary.primary900,
    },
    uploadBox: {
        border: `1px solid ${theme.palette.textV2.tertiary.tertiary800}`,
    },
    uploadText: {
        color: theme.palette.textV2.tertiary.tertiary800,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    textHeader: { color: theme.palette.textV2.tertiary.tertiary900 },
    datePicker: {
        border: "1px solid",
        borderColor: "#DDD",
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: 8,
        "&:hover": {
            borderColor: "#FD6202",
            borderWidth: 2,
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: "#DDD",
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `#FD6202 !important`,
            borderWidth: "2px !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `#FD6202 !important`,
        },
    },
}));

export enum INVENTORY_WAREHOUSE {
    DELHI = 1,
    MUMBAI = 2,
}

interface IAddVendorProductPriceFormTemplate {
    formik: FormikProps<IVendorProductPriceForm>;
    buttonShow?: boolean;
    setWareHouseId?: Dispatch<SetStateAction<number>>;
    mode: string
}

export interface IInventoryAddViewProps {
    warehouseId: number;
}

const AddPriceSectionTemplate: React.FC<IAddVendorProductPriceFormTemplate> = ({ formik, buttonShow, setWareHouseId, mode = 'ADD' }) => {

    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vendorInventoryService = useVendorInventoryService();

    const [activeTab, setActiveTab] = useState(INVENTORY_WAREHOUSE.DELHI);
    const [warehouses, setWarehouseTo] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);

    const fetchWarehouses = async () => {
        if (user?.businessId) {
            try {
                const wareHouseResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId, status: STATUS.ACTIVE });
                if (wareHouseResponse.status === HTTP_STATUS.OK) {
                    const warehouses = wareHouseResponse?.data?.data?.content;
                    setWarehouseTo(warehouses);
                }
                else {
                    setWarehouseTo([]);
                    showSnackbar('error', 'Warehouses fetch failed');
                }
            } catch (error) {
                showSnackbar('error', 'Warehouses fetch failed');
            }
        }
    };

    const schema: ITabsSchema[] = warehouses?.map((warehouse: { id: number, name: string }, index: number) => ({
        label: capitalizeFirstLetter(warehouse?.name),
        component: <PriceInformationTemplate warehouseId={warehouse.id} formik={formik} index={index} warehouseName={warehouse?.name} mode={mode} />
    }));

    useEffect(() => {
        fetchWarehouses();
    }, [user?.businessId]);

    return (
        <div>
            <div className={`${classes.selectBox} rounded-xl p-4 grid gap-4`}>
                <div className='w-full relative'>
                    {schema.length > 0 && (
                        <TabsV2 schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default AddPriceSectionTemplate