import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { VALUE_ADDED_SERVICE_ROUTES, initialPaginationValues } from '../../../../../utils/constant';
import Button from '../../../../atoms/Button/Button';
import { HTTP_STATUS, IPagination } from '../../../../../utils/types';
import { useSnackbar } from '../../../../../hooks/useSnackBar';
import { IMachineRequestParams, IMachineSearchParams, useVASMasterCategoryService } from '../../../../../services/useVASMasterCategoryService';
import MachineListTemplate from '../../../../templates/DashBoard/ValueAddedService/Machine/MachineList.template';
import { useAuthenticatedUser } from '../../../../../hooks/useAuthenticatedUser';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

const initialMachineRequestFilters: IMachineRequestParams = {
    uscIds: [],
    serviceCategoryIds: [],
};

const MachineListPage: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { user } = useAuthenticatedUser();
    const initialMachineFiltersValues: IMachineSearchParams = {
        search: "",
        status: "ALL",
        sort: "createdAt,desc",
        vendorId: user?.businessId,
    }
    const vasMasterCategoryService = useVASMasterCategoryService();
    const [filters, setFilters] = useState<IMachineSearchParams>(initialMachineFiltersValues);
    const [machineRequestFilters, setMachineRequestFiltersTo] = useState<any>(initialMachineRequestFilters);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [machines, setMachines] = useState<any>([])
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const refreshMachine = async (page: number, size: number) => {
        const payload: IMachineRequestParams = {
            uscIds: machineRequestFilters?.uscIds?.length > 0 ? machineRequestFilters?.uscIds : null,
            serviceCategoryIds: machineRequestFilters?.serviceCategoryIds?.length > 0 ? machineRequestFilters?.serviceCategoryIds : null,
        };
        await vasMasterCategoryService.getMachines(
            {
                page: page,
                size: size,
                sort: filters?.sort,
                search: filters?.search?.trim(),
                status: filters?.status === "ALL" ? "" : filters?.status,
                vendorId: filters?.vendorId
            },
            payload
        )
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = res?.data?.data;
                    setPagination({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setMachines(res?.data?.data?.content)
                }
            }).catch(error => {
                showSnackbar('error', error);
                setMachines([]);
            })
    }

    const handleFiltersChange = (name: string, value: any) => {
        setPagination({ ...pagination, page: 0 });
        setFilters({ ...filters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 0,
            size: parseInt(event.target.value, 10)
        }));
    };

    useEffect(() => {
        refreshMachine(pagination.page, pagination.size);
    }, [pagination.page, pagination.size, filters, machineRequestFilters]);

    const handleCreate = () => {
        navigate(`${VALUE_ADDED_SERVICE_ROUTES.MACHINES_CREATE}`);
    };

    return (
        <div className='grid gap-y-4 mt-5'>
            <div className='flex justify-between'>
                <div className={`${classes.heading} text-base font-semibold my-auto`}>Machines</div>
                <Button variant='primaryContained' label={"+ Add Machine"} onClick={handleCreate}/>
            </div>
            {SnackBarComponent}

            <MachineListTemplate
                machines={machines}
                pagination={pagination}
                handlePaginationChange={handlePaginationChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handleFiltersChange={handleFiltersChange}
                filters={filters}
                machineRequestFilters={machineRequestFilters}
                setMachineRequestFiltersTo= {setMachineRequestFiltersTo}
            />
        </div>
    )
}

export default MachineListPage