import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioActive from '../../../assets/images/radioActive.svg';
import RadioInActive from '../../../assets/images/radioInactive.svg'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ICustomisationDetails, IImageDetails } from './CustomisationDetails.template';
import { IVendorCatalogueAddForm } from '../../pages/VendorCatalogue/VendorCatalogueAdd.page';
import { FormikProps } from 'formik';

interface ProductDetailsDropdownTemplateProps {
    data: ICustomisationDetails;
    formik: FormikProps<IVendorCatalogueAddForm>;
    newIndex: number;
    index: number;
    selectedService: any;
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    subHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    mainHeading: {
        color: theme.palette.textV2.primary.primary500,
    },
    image: {
        width: "141px",
        height: "159px"
    }
}));

const ProductDetailsDropdownTemplate: React.FC<ProductDetailsDropdownTemplateProps> = ({ data, formik, newIndex, index, selectedService }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState<Boolean>(false);

    const handleCheckboxChange = (event: React.MouseEvent<HTMLButtonElement>, machineId: number) => {
        let productServicesDetails = formik.values.catalogueProductDetails[index]?.productServicesDetails || [];

        const machineIndex = productServicesDetails.findIndex((detail) => detail.machineId === machineId);

        if (machineIndex !== -1) {
            productServicesDetails[machineIndex].machineId = null;

            productServicesDetails = productServicesDetails.filter((detail) => detail.machineId !== null);

            formik.setFieldValue(`catalogueProductDetails.${index}.productServicesDetails`, productServicesDetails);
        } else {
            productServicesDetails = productServicesDetails.filter((detail) => detail.machineId !== null);
            const newProductServiceDetails = [...productServicesDetails, { machineId, locationId: data.locationId, selectedService: selectedService }];
            formik.setFieldValue(`catalogueProductDetails.${index}.productServicesDetails`, newProductServiceDetails);
        }
    };

    return (
        <div className={`${classes.selectBox} p-6 mb-6 rounded-xl`}>
            <div className='flex items-center'>
                <div>
                    <RadioGroup
                        row
                        className='flex gap-6'
                    >
                        <FormControlLabel
                            control={
                                <Radio
                                    icon={<img src={RadioInActive} alt="Inactive" />}
                                    checkedIcon={<img src={RadioActive} alt="Active" />}
                                    value={data.machineId}
                                    checked={formik.values.catalogueProductDetails[index]?.productServicesDetails.some(
                                        (detail) => detail.machineId === data?.machineId
                                    )}
                                    onClick={(e) => handleCheckboxChange(e, data?.machineId)}
                                />
                            }
                            label={""}
                            className={` `}
                        />
                    </RadioGroup>

                </div>
                <div className={`${classes.heading} font-medium text-2xl`}>
                    {data.machineName}
                </div>
                {/* <span className={`${classes.mainHeading} font-medium text-2xl ml-1`}> .</span> */}
                <div className='flex justify-end ml-auto'>
                    <button onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </button>
                </div>
            </div>
            {isOpen && (
                <div>
                    <div className={`${classes.borderBottom} my-6`} />
                    <div className=''>
                        <div className={`${classes.selectBox}  rounded-xl p-3 w-full`}>
                            <div className={`${classes.heading} font-medium text-base mb-6`}>Sheet Width</div>
                            <div className="flex gap-x-3">
                                <div className={`${classes.selectBox} w-56 rounded-xl p-6`}>
                                    <div className={`${classes.subHeading} text-sm`}>Minimum Width (mm)</div>
                                    <div className={`${classes.subHeading} font-medium text-base `}>{data.minWidth || "-"}</div>
                                </div> <div className={`${classes.selectBox} w-56 rounded-xl p-6`}>
                                    <div className={`${classes.subHeading} text-sm`}>Maximum Width (mm)</div>
                                    <div className={`${classes.subHeading} font-medium text-base `}>{data.maxWidth || "-"}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.selectBox}  rounded-xl p-3 w-full my-6`}>
                            <div className={`${classes.heading} font-medium text-base mb-6`}>Tolerance</div>
                            <div className="flex gap-x-3">
                                <div className={`${classes.selectBox} w-56 rounded-xl p-6`}>
                                    <div className={`${classes.subHeading} text-sm`}>Plus Value</div>
                                    <div className={`${classes.subHeading} font-medium text-base `}>-</div>
                                </div> <div className={`${classes.selectBox} w-56 rounded-xl p-6`}>
                                    <div className={`${classes.subHeading} text-sm`}>Minus Value</div>
                                    <div className={`${classes.subHeading} font-medium text-base `}>-</div>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.selectBox}  rounded-xl p-3 w-full`}>
                            <div className={`${classes.heading} font-medium text-base mb-6`}>VAS Pricing</div>
                            <div className="flex gap-x-3">
                                <div className={`${classes.selectBox} w-56 rounded-xl p-6`}>
                                    <div className={`${classes.subHeading} text-sm`}>Price per MT</div>
                                    <div className={`${classes.subHeading} font-medium text-base `}>{data.machinePricePerMT || "-"}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.selectBox}  rounded-xl p-3 w-full mt-6`}>
                            <div className={`${classes.heading} font-medium text-base mb-6`}>Image of the Machine</div>
                            <div className={`${classes.subHeading} font-medium text-base mb-3`}>Service Images</div>
                            <div className="flex gap-x-3">
                                {data.vasMachineImageDetails?.map((image: IImageDetails, newIndex: number) => (
                                    <img className={`${classes.image} rounded-lg`} src={image.path} alt="image" />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductDetailsDropdownTemplate;
