import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Search from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import advanceFilterIcon from '../../../assets/icons/advanceFilterIcon.svg';
import { FormHelperText } from '@mui/material';
import { IconButton } from '@mui/joy';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { TablePagination, useTheme, Input } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { VENDOR_CATALOGUE_FORM_STATES, ADVANCE_FILTER_TYPE, HTTP_STATUS, IPagination, IcatalogueName, VENDOR_PRODUCT_DETAILS_TYPE } from '../../../utils/types';
import { TablePaginationActionsProps } from '../Catalogue/CatalogueList.template';
import Button from '../../atoms/Button/Button';
import { useSnackbar } from '../../../hooks/useSnackBar';
import UpcMpcSearchDialogModal, { IMaterialFilterData } from '../GenericSearchDialog/GenericSerachDialog.template';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import { IDetails } from '../VendorManagement/ProductCatalogueView.template';
import { CUSTOMER_ROUTES, GENERIC_FILTER_SECTIONS, PRODUCT_SECTION, SHAPE_TYPES, STATUS, VENDOR_CATALOGUE_ROUTES, initialPaginationValues } from '../../../utils/constant';
import { IVendorCatalogueAddForm } from '../../pages/VendorCatalogue/VendorCatalogueAdd.page';
import { VendorCatalogueSectionProp } from './VendorCatalogueForm.template';
import HoverButton from '../../atoms/Button/HoverButton';
import ProductViewTemplate from '../DashBoard/Inventory Management/ProductView.template';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import { usePostProductService } from '../../../services/usePostProductService';
import { capitalizeFirstLetter, makeingCatalogueNamebyInputs } from '../../../utils/helper';
import EmptyStateCard from '../../molecules/EmptyStateCard/EmptyStateCard';
import { IProductData } from '../DashBoard/Inventory Management/SelectProduct.template';
import SuccessFailurePopup from '../../molecules/SuccessFailurePopup/SuccessFailurePopup';
import { IProductPrice, useVendorPriceService } from '../../../services/useVendorPriceSerivce';
import { IVendorProductPriceDetails } from '../../pages/VendorManagement/VendorProductPriceCreate.page';
import { IAddNewInventoryDetail } from '../DashBoard/Inventory Management/AddNewVendorInventory.template';

export interface IRequestBodyValues {
    upcIds: (string | number)[] | null;
    brandIds: (string | number)[] | null;
}
interface IProductDetails {
    inventory: boolean;
    price: boolean;
}
interface IErrorDetails {
    name: string;
    id: number;
    details: IProductDetails;
}


const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
    search: {
        margin: "0",
        background: theme.palette.background.primaryLight,
    },
    searchIcon: {
        color: theme.palette.icon.primaryLight
    },
    paginationTable: {
        "& .MuiTablePagination-selectLabel": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-input": {
            borderRadius: '8px',
            border: '1px solid #E6E6E6',
            width: '80px',
            paddingRight: '10px',
            marginRight: "24px",
            height: "30px"
        },
        "& .MuiTablePagination-displayedRows": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-spacer": {
            flex: 0
        },
        "& .MuiToolbar-root": {
            paddingLeft: "0px !important",
            paddingRight: "0px",
            width: "100%"
        },
    },
    paginationComponent: {
        color: theme.palette.pagination.text.secondaryLight,
        fontWeight: 500,
        fontSize: "14px",
        width: "100%",
        justifyContent: 'end',
    },
    inputBar: {
        width: "54px",
        height: "28px",
        borderRadius: "8px",
        border: `1px solid ${theme.palette.border.tritiaryLight}`,
        paddingLeft: "16px"
    }

}));

const SelectProductIdFormTemplate: React.FC<VendorCatalogueSectionProp> = ({ formik, setCurrentSectionTo, mode }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { user } = useAuthenticatedUser();
    const [filterSection, setFilterSections] = useState<string[]>([]);
    const [upcFilterData, setUpcFilterData] = useState<IMaterialFilterData>({});
    const [requestBodyValues, setRequestBodyValuesTo] = useState<IRequestBodyValues>({ upcIds: null, brandIds: null });
    const [productsData, setProductsData] = useState<IProductData[]>([{ id: null, skuCode: '', productName: '' }]);
    const vendorInventoryService = useVendorInventoryService();
    const { showSnackbar } = useSnackbar();
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const postProductService = usePostProductService();
    const [productId, setProductId] = useState<number>();
    const [productData, setProductData] = useState<IProductData | null>(null);
    const [warehouseData, setWarehouseData] = useState<IErrorDetails[]>([
        {
            name: '',
            id: 0,
            details: {
                price: true,
                inventory: true,
            },
        },
    ]);
    const [showPopUpPrice, setshowPopUpPrice] = useState(false);
    const [showPopUpInventory, setshowPopUpInventory] = useState(false);
    const [showPopUpBoth, setshowPopUpBoth] = useState(false);
    const [vendorProductPrice, setVendorProductPriceTo] = useState<IVendorProductPriceDetails[]>([]);
    const vendorPriceService = useVendorPriceService();
    const [namesWithPriceFalse, setNamesWithPriceFalse] = useState<string[]>([]);
    const [namesWithInventoryFalse, setNamesWithInventoryFalse] = useState<string[]>([]);
    const [namesWithBothFalse, setNamesWithBothFalse] = useState<string[]>([]);
    const [loading, isLoading] = useState<boolean>(false);



    const handleCancel = () => {
        navigate(VENDOR_CATALOGUE_ROUTES.MAKE_CATALOGUE);
    };
    const handleBack = () => {
        setCurrentSectionTo(VENDOR_CATALOGUE_FORM_STATES.PRODUCT_TYPE);
    }

    const getVedorProductbyId = async () => {
        if (user?.businessId && formik?.values?.productId) {
            try {
                const productId = formik?.values?.productId;
                const ProductsResponse = await vendorInventoryService.getDetailedProduct(user?.businessId, productId, { vendorProductDetailsType: VENDOR_PRODUCT_DETAILS_TYPE.VENDOR_PRODUCT_USN_DETAILS });
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data;
                    setProductData({
                        id: productData?.id,
                        productId: productData?.productId,
                        skuCode: productData?.standardProductDetails.upcCode,
                        productName: productData?.productUpcTitle,
                        standardProductDetails: productData?.standardProductDetails,
                        vendorProductStockDetails: productData?.vendorProductStockDetails
                    });
                }
            } catch (error) {
                showSnackbar('error', 'Detailed Product Price fetch failed');
            }
        }
    };

    const getVedorProductPrices = async () => {
        if (user?.businessId && formik.values.productId) {
            try {
                const params: IProductPrice = {
                    page: pagination.page,
                    size: pagination.size,
                };
                const vedorInventoriesResponse = await vendorPriceService.getAllPriceProducts(formik.values.productId, params);
                if (vedorInventoriesResponse.status === HTTP_STATUS.OK) {
                    const vendorInventoryData = vedorInventoriesResponse?.data?.data?.content.map((inventory: any) => ({
                        id: inventory.warehouseId,
                        marketPrice: inventory.marketPrice,
                        reserveSalesPrice: inventory.reserveSalesPrice,
                        validityTo: inventory.validityTo,
                        validityFrom: inventory.validityFrom,
                        name: inventory.warehouseLocation,
                        status: inventory.status
                    }));
                    setVendorProductPriceTo(vendorInventoryData);
                    setPagination((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalRecords: vedorInventoriesResponse?.data?.data?.totalElements,
                    }));
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }

    };


    const fetchWarehouse = async () => {
        if (user && user.businessId) {
            try {
                const wareHouseResponse = await vendorInventoryService.getAllWarehouseMappedVendor({
                    vendorId: user.businessId,
                    status: STATUS.ACTIVE,
                });

                if (wareHouseResponse.status === HTTP_STATUS.OK) {
                    const warehouses = wareHouseResponse.data.data.content;

                    const formattedData: IErrorDetails[] = warehouses.map((warehouse: any) => ({
                        name: capitalizeFirstLetter(warehouse.name),
                        id: warehouse.id,
                        details: {
                            inventory: false,
                            price: false,
                        },
                    }));

                    setWarehouseData(formattedData);
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }
    };

    const getVedorInventoryProductbyId = async (warehouseId: number) => {
        if (user?.businessId && formik.values.productId) {
            try {
                const ProductsMappedInventoryResponse = await vendorInventoryService.getAllInvendorMappedVenndor(
                    user?.businessId,
                    warehouseId,
                    formik.values.productId,
                    { classType: 'STANDARD', page: pagination.page, size: pagination.size }
                );

                if (ProductsMappedInventoryResponse.status === HTTP_STATUS.OK) {
                    const content: IAddNewInventoryDetail[] = ProductsMappedInventoryResponse.data.data.content;
                    setPagination((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalRecords: ProductsMappedInventoryResponse?.data?.data?.totalElements,
                    }));

                    // Return whether the inventory is available or not
                    return content.length > 0;
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }
        return false;
    };


    const onNext = (event: React.FormEvent) => {
        if(loading) {
            return;
        }
        if (namesWithInventoryFalse.length != 0) {
            setshowPopUpInventory(true);
            return;
        }
        if (namesWithPriceFalse.length != 0) {
            setshowPopUpPrice(true);
            return;
        }
        if (namesWithBothFalse.length != 0) {
            setshowPopUpBoth(true);
            return;
        }
        event.preventDefault();
        const requiredFields = ["productId"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
            if (formik.values.productId == null) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        formik.setValues((prevValues: IVendorCatalogueAddForm) => ({
            ...prevValues,
        }));
        if (fieldError === true) return;
        setCurrentSectionTo(VENDOR_CATALOGUE_FORM_STATES.ADD_PRICING);
    }

    const handleFilterClear = () => {
        setUpcFilterData({});
    }

    const onFilterSubmit = () => {
        setRequestBodyValuesTo({ upcIds: upcFilterData?.productIds ?? null, brandIds: null });
        if (!upcFilterData?.productIds?.[0]) {
            showSnackbar("error", "Please select a product")
            return;
        }
        formik.setFieldValue('productId', Number(upcFilterData?.productIds?.[0]));
        setCurrentSectionTo(VENDOR_CATALOGUE_FORM_STATES.ADD_PRICING);

    }
    const clearAllData = () => {
        setUpcFilterData({});
        setRequestBodyValuesTo({ upcIds: null, brandIds: null });
    }

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination: IPagination) => ({
            ...prevPagination,
            page: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPagination((prevPagination) => ({
            ...prevPagination,
            size: newRowsPerPage
        }));
    };


    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
        const [inputPage, setInputPage] = useState(page + 1);

        const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        const handleInputPageChange = (event: any) => {
            setInputPage(parseInt(event.target.value, 10));
        };
        const handleInputBlur = (event: any) => {
            onPageChange(event, inputPage - 1);
        };

        return (
            <div className={`flex gap-x-6 ${classes.paginationComponent}`}  >
                <div className="flex gap-x-2.5">
                    <div className='my-auto'>Page</div>
                    <div className='my-auto'>
                        <Input
                            type="number"
                            value={inputPage}
                            onChange={handleInputPageChange}
                            onBlur={handleInputBlur}
                            disableUnderline={true}
                            inputProps={{ min: 1, max: Math.ceil(count / rowsPerPage) }}
                            style={{ width: '54px', height: "28px", borderRadius: '8px', border: '1px solid #E6E6E6', paddingLeft: '16px' }}
                        />
                    </div>
                    <div className='my-auto'>of {Math.ceil(count / rowsPerPage)}</div>
                </div>

                <div className={`flex`}>
                    <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </IconButton>
                    <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </IconButton>
                    <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </div>
            </div>
        );
    }

    const handleInventoryClick = () => {
        navigate('/dashboard/inventory-management');
    };

    const handlePriceClick = () => {
        navigate(`${CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_LISTING}`);
    };

    const getVedorProduct = async () => {
        if (user?.businessId) {
            try {
                const ProductsResponse = await vendorInventoryService.getAllProducts(user?.businessId, { classType: "STANDARD", page: pagination.page, size: pagination.size, search: searchTerm });
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data?.content;
                    setProductsData(productData);
                    setPagination((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalPages: ProductsResponse?.data?.data?.totalPages,
                        totalRecords: ProductsResponse?.data?.data?.totalElements
                    }));
                }
            } catch (error) {
                showSnackbar('error', 'Products fetch failed');

            }
        }
    };
    useEffect(() => {
        getVedorProduct();
    }, [pagination.page, pagination.size, searchTerm]);

    useEffect(() => {
        fetchWarehouse();
    }, []);

    useEffect(() => {
        getVedorProductbyId();
        getVedorProductPrices();
    }, [formik.values.productId]);

    const handleChange = (event: any, productId?: number) => {
        setProductId(productId);
        mode === "ADD" && formik.setFieldValue('productId', event.target.value);
    }
    useEffect(() => {
        setFilterSections([GENERIC_FILTER_SECTIONS.UPC, GENERIC_FILTER_SECTIONS.MATERIAL_FILTER, GENERIC_FILTER_SECTIONS.OTHER]);
    }, [])

    const getProductDetails = async () => {
        try {
            const response = await postProductService.getProductDetailsById(productId ?? 0)
            if (response.status === HTTP_STATUS.OK) {
                const productData = response.data.data;
                const input: IcatalogueName = {
                    brand: productData.brand,
                    productCategory: productData.productCategory,
                    shape: SHAPE_TYPES.find(type => type.id === productData?.shape)?.name || '',
                    standard: productData.standard,
                    grade: productData.grade,
                };
                formik.setFieldValue('name', makeingCatalogueNamebyInputs(input));
            }
        }
        catch (error) {
            console.error("Error", "product detail fetch failed")
        }
    }

    useEffect(() => {
        if (formik.values.productId) {
            getProductDetails()
        }
    }, [formik.values.productId])

    useEffect(() => {
        const updateWarehouseData = async () => {
            isLoading(true);
            const updatedWarehouseData = await Promise.all(
                warehouseData.map(async (warehouse) => {
                    const [isInventoryAvailable, isPriceAvailable] = await Promise.all([
                        getVedorInventoryProductbyId(warehouse.id),
                        vendorProductPrice.some((vendorPrice) => vendorPrice.id === warehouse.id),
                    ]);

                    return {
                        ...warehouse,
                        details: {
                            ...warehouse.details,
                            inventory: isInventoryAvailable,
                            price: isPriceAvailable,
                        },
                    };
                })
            );

            setWarehouseData(updatedWarehouseData);
        };

        updateWarehouseData();
    }, [vendorProductPrice]);

    useEffect(() => {
        const priceFalseNames: string[] = [];
        const inventoryFalseNames: string[] = [];
        const inventoryPriceFalseNames: string[] = [];


        for (const warehouse of warehouseData) {
            const { name, details } = warehouse;
            const { price, inventory } = details || {};

            if (!price && !inventory) {
                if(warehouseData.length === 1  && name.trim()){
                    inventoryPriceFalseNames.push(name);
                }
                else {
                    continue;
                }
              
            }

            if (!price && inventory) {
                priceFalseNames.push(name);
            }

            if (!inventory && price) {
                inventoryFalseNames.push(name);
            }
        }

        setNamesWithPriceFalse(priceFalseNames);
        setNamesWithInventoryFalse(inventoryFalseNames);
        setNamesWithBothFalse(inventoryPriceFalseNames)
        isLoading(false);
    }, [warehouseData]);

    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-3/5 ">
                    <div className='flex gap-x-2 mx-auto '>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Catalog Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5 ">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto  font-semibold`}>Select Product </div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Enter Pricing Details</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Customisation </div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>
            {showPopUpInventory &&
                <SuccessFailurePopup
                    setShowPopup={setshowPopUpInventory}
                    status={false}
                    height='459px'
                    width='458px'
                    isInfo={true}
                    heading="Inventory Not Added"
                    description={`There is no inventory details linked with this product for location ${namesWithInventoryFalse.join(', ')}`}
                    button1={{
                        text: "Choose Another",
                        variant: "tertiaryContained",
                        size: "medium",
                        onClick: () => setshowPopUpInventory(false)
                    }}
                    button2={{
                        text: "Add Inventory",
                        variant: "secondaryContained",
                        size: "large",
                        onClick: handleInventoryClick,
                    }}
                />
            }
            {showPopUpPrice &&
                <SuccessFailurePopup
                    setShowPopup={setshowPopUpPrice}
                    status={false}
                    height='459px'
                    width='458px'
                    isInfo={true}
                    heading="Price Not Added"
                    description={`There is no price details linked with this product for location ${namesWithPriceFalse.join(', ')}`}
                    button1={{
                        text: "Choose Another",
                        variant: "tertiaryContained",
                        size: "medium",
                        onClick: () => setshowPopUpPrice(false)
                    }}
                    button2={{
                        text: "Add Price",
                        variant: "secondaryContained",
                        size: "large",
                        onClick: handlePriceClick,
                    }}
                />
            }
            {showPopUpBoth &&
                <SuccessFailurePopup
                    setShowPopup={setshowPopUpBoth}
                    status={false}
                    height='459px'
                    width='458px'
                    isInfo={true}
                    heading="Price and Inventory Not Added"
                    description={`There is no price details and inventory linked with this product for location ${namesWithBothFalse.join(', ')}`}
                    button1={{
                        text: "Add Price",
                        variant: "tertiaryContained",
                        size: "medium",
                        onClick: handlePriceClick
                    }}
                    button2={{
                        text: "Add Inventory",
                        variant: "secondaryContained",
                        size: "medium",
                        onClick: handleInventoryClick,
                    }}
                    button3={{
                        text: "Choose Another",
                        variant: "tertiaryText",
                        size: "medium",
                        onClick: () => setshowPopUpBoth(false)
                    }}
                />
            }
            <div className=' grid gap-4 '>
                <div className='flex  justify-between'>
                    <div className=' grid gap-1/2'>
                        <text className={`${classes.textHeading} text-base font-medium`}>Search and Select Product </text>
                        <text className={`${classes.barHeading} text-xs font-normal`}> Please select the product you want to add</text>
                    </div>
                    <div className='flex gap-1'>
                        <TextFieldV2
                            className=''
                            size="medium"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                className: "px-2 h-10 text-base ",
                                startAdornment: (
                                    <InputAdornment position="start" className='px-3'>
                                        <Search className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                        />

                        <UpcMpcSearchDialogModal
                            sections={filterSection}
                            productType={PRODUCT_SECTION.BRAND_UPC}
                            filterData={upcFilterData}
                            setFilterData={setUpcFilterData}
                            handleFilterClear={handleFilterClear}
                            onFilterSubmit={onFilterSubmit}
                            isSingleSelect={true}
                            triggerButton={
                                <HoverButton variant="tertiaryContained"
                                    iconButton={<img src={advanceFilterIcon} alt="Filter Icon" />}
                                    label="Advance Filters"
                                    buttonWithImg={true}
                                    hoverButton={!!upcFilterData?.productIds?.length}
                                    hoverButtonLabel={upcFilterData?.productIds?.length}
                                    handleCloseIcon={clearAllData}
                                    size='medium'
                                />
                            }
                            advanceFilterType={ADVANCE_FILTER_TYPE.VENDOR_PRODUCTS}
                        />
                    </div>
                </div>
                {
                    productsData.length === 0 ? (
                        <EmptyStateCard
                            message="No Products Available"
                        />
                    ) : (
                        <div className={`${classes.selectBox} rounded-xl p-4 grid gap-3`}>
                            {productsData.map((product: IProductData, index) => (
                                <ProductViewTemplate
                                    key={index}
                                    product={product}
                                    formik={formik}
                                    showButton={true}
                                    handleChange={handleChange}
                                    showAttributes={true}
                                    showUsnDetails={true}
                                />
                            ))}
                            {formik.touched?.productId && formik.errors?.productId && (
                                <FormHelperText error className="top-half">
                                    {formik.errors?.productId}
                                </FormHelperText>
                            )}
                            <TablePagination
                                className={`${classes.paginationTable} w-full mt-4 flex`}
                                component="div"
                                rowsPerPageOptions={[5, 10, 15]}
                                count={pagination.totalRecords}
                                page={pagination.page}
                                onPageChange={handlePaginationChange}
                                rowsPerPage={pagination.size}
                                ActionsComponent={TablePaginationActions}
                                onRowsPerPageChange={handleRowsPerPageChange}
                            />
                        </div>
                    )
                }
            </div>
            <div className=' flex justify-between'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
                <div className='flex gap-6 '>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleBack}
                    />
                    <Button
                        variant="primaryContained"
                        label="Next"
                        onClick={onNext}
                    />
                </div>
            </div>
        </div>
    )
}

export default SelectProductIdFormTemplate