import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import EquipmentFormTemplate from './EquipmentForm.template';
import MachineOverviewFormTemplate from './MachineOverviewForm.template';
import CapacityDetailsFormTemplate from './CapacityDetailsForm.template';
import ServicePriceFormTemplate from './ServicePriceFrom.template';
import ImageUploadFormTemplate from './ImageUploadForm.template';
import UscAndServiceFormTemplate from './UscAndServiceForm.template';
import AttributeLinkedFormTemplate from './AttributeLinkedForm.template';
import MachineDescriptionFormTemplate from './MachineDescriptionForm.template';
import { useSnackbar } from '../../../../../hooks/useSnackBar';
import { MODE, SERVICE_STATUS_OPTIONS } from '../../../../../utils/constant';
import Button from '../../../../atoms/Button/Button';
import StatusV2 from '../../../../atoms/Status/StatusV2';
import { capitalizeFirstLetter } from '../../../../../utils/helper';
import { MACHINE_FORM_STATE } from '../../../../pages/DashBoard/ValueAddedService/Machine/MachineCreate.page';

interface MainServiceFormTemplateProps {
    mode: string,
    formik: any;
    onBack: () => void,
    setFormStateTo: (state: MACHINE_FORM_STATE) => () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    componentHeading:{
        color:theme.palette.v3.text._primaryDark
    },
    componentSubHeading:{
        color:theme.palette.v3.text.primaryLight
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.backgroundV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    icon: {
        backgroundColor: "#FD6202",
        color: "white",
        width: "48px",
        height: "48px",
        borderRadius: "10px",
    },

}));

const MachineFormTemplate: React.FC<MainServiceFormTemplateProps> = ({ mode, onBack, formik, setFormStateTo }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [isNextButtonDisabled,setIsNextButtonDisabled]=useState(true)

    const checkErrors = () => {
        const errors = Object.keys(formik.errors).find(key => 
            ['serviceCategory', 'usc', 'subAttributeIds', 'machineName', 'machineVendor', 'modelNumber', 'location', 'machineType', 'manufactureYear','operationalSpeed','equipmentName','equipmentDescription','materialHandle','qualityStandard','materialGrade','serviceability','minOrderQuantity','maxOrderQuantity','serviceCharge','machineDescription','status','attributes','warehouse'].includes(key)
        );
        return !!errors;
    };

    const handleNext = () => {
        formik.setTouched({
            serviceCategory: true,
            usc: true,
            uscId: true,
            subAttributeIds: true,
            machineName: true,
            machineVendor: true,
            modelNumber: true,
            location: true,
            machineType: true,
            manufactureYear: true,
            operationalSpeed: true,
            equipmentName: true,
            equipmentNames: true,
            equipmentDescription: true,
            materialHandle: true,
            qualityStandard: true,
            materialGrade: true,
            serviceability: true,
            minOrderQuantity: true,
            maxOrderQuantity: true,
            serviceCharge: {
                normalServiceCharge: true,
                minimumServiceCharge: true,
                maximumServiceCharge: true,
            },
            machineDescription: true,
            status: true,
            attributes: true,
            images: true,
            imageListToDelete: true,
            warehouse: true,
        }, true)
        const errors = Object.keys(formik.errors).find(key => ['serviceCategory', 'usc', 'uscId', 'subAttributeIds', 'machineName', 'machineVendor', 'modelNumber', 'location', 'machineType' ,'manufactureYear' ,'operationalSpeed', 'equipmentName', 'equipmentNames', 'equipmentDescription', 'materialHandle', 'qualityStandard', 'materialGrade', 'serviceability', 'minOrderQuantity', 'maxOrderQuantity', 'serviceCharge', 'machineDescription', 'status', 'attributes', 'images', 'imageListToDelete', 'warehouse'].includes(key))
        if (errors) return;
        setFormStateTo(MACHINE_FORM_STATE.MACHINE_REVIEW)();
    }

    useEffect(() => {
        setIsNextButtonDisabled(checkErrors());
    }, [formik.errors]);

    return (
        <form className="grid gap-y-6" >
            {SnackBarComponent}
            <div className={`${classes.mainHeading} text-lg font-medium`}>{mode === MODE.UPDATE ? "Update" : mode === MODE.VIEW ? "View" : "Add"} Machine</div>
            <div className={`w-full flex justify-between gap-x-2 text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>Machine Details</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded`}></div>
                </div>
            </div>
            <div className='grid gap-y-4 mt-1'>
                <div>
                    <div className={`${classes.componentHeading} text-base font-medium`}>Machine Details</div>
                    <div className={`${classes.componentSubHeading} text-xs font-normal`}>Please fill out the details to create Machine</div>
                </div>
                <UscAndServiceFormTemplate mode={mode} formik={formik} />
                <AttributeLinkedFormTemplate mode={mode} formik={formik} />
                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Machinery Information</div>
                    <MachineOverviewFormTemplate mode={mode} formik={formik} />
                    <EquipmentFormTemplate mode={mode} formik={formik} />
                    <CapacityDetailsFormTemplate mode={mode} formik={formik} />
                    <ServicePriceFormTemplate mode={mode} formik={formik} />
                    <MachineDescriptionFormTemplate mode={mode} formik={formik} />
                    <ImageUploadFormTemplate mode={mode} formik={formik} />
                </div>
                <StatusV2 heading="Machine Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className="flex justify-end gap-4">
                <Button variant="tertiaryContained" label='Cancel' onClick={onBack} />
                {mode !== MODE.VIEW && <Button variant="secondaryContained" onClick={handleNext} label={`Next`} />}
            </div>
        </form>
    )
}

export default MachineFormTemplate;