import { createUseStyles } from "react-jss";
import { IAttribute } from "../../pages/Catalogue/CatalogueDetails.page";
import { formatUOM, sortSingleUpcAttributes } from "../../../utils/helper";
import { useEffect, useState } from "react";
import useMetaDataService from "../../../hooks/useMetaDataService";
import { ATTRIBUTE_LABEL, COLOR_DATA } from "../../../utils/constant";
import { IColorPalette } from "./AttributeSelection.template";
import { Try } from "@mui/icons-material";

export interface ISecondaryProductAttributesTemplateProps {
    attributes: IAttribute | {};
}

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    container: {
        color: theme.palette.textV2.tertiary.tertiary700,
        border: `1px solid ${theme.palette.v4.border._secondaryDark}`,
        padding: "8px 0px",
    },
    attributes: {
        color: theme.palette.v4.text._primaryDarkAccent,
        border: `1px solid ${theme.palette.v4.border._secondaryDarkAccent}`,
        padding: "8px 16px",
        fontSize: "16px",
    },
    attributeNames: {
        color: theme.palette.text._primaryDarkAccent
    },
    attributOption: {
        color: theme.palette.v4.text.secondaryLightAccent,
    },
    attributeContainer: {
        fontSize: "14px"
    },
    attributeName: {
        color: theme.palette.v4.border.secondaryDark,
    },
    uom: {
        fontSize: "12px"
    },
    attribute: {
        minWidth: "33%",
        margin: 0,
        '&:nth-child(n+4)': {
            borderTop: `1px solid ${theme.palette.border._primaryLight}`,
        }
    },
    heading: {
        color: theme.palette.textV2.secondary.secondary900,
    },
    attributeValue: {
        color: theme.palette.v4.text._primaryDarkAccent,
    },
    mobileContainer: {
        display: "none"
    },
    "@media (max-width: 480px)": {
        webContainer: {
            display: "none"
        },
        container: {
            color: theme.palette.text.secondaryDark,
            border: `none`,
            padding: "0px",
        },
        mobileContainer: {
            display: "grid"
        },
        attributes: {
            fontSize: "14px",
        },
        attribute: {
            '&:nth-child(n+4)': {
                borderTop: `none`,
            }
        },
        attributeContainer: {
            fontSize: "12px"
        },
        uom: {
            fontSize: "10px"
        },
        attributeNames: {
            color: theme.palette.textV2.tertiary.tertiary600,
        },
        attributeName: {
            color: theme.palette.textV2.tertiary.tertiary600,
        },
        attributOption: {
            color: theme.palette.textV2.tertiary.tertiary600,
        },
    }
}));

const SingleUpcAttributeReviewForm: React.FC<ISecondaryProductAttributesTemplateProps> = ({ attributes }) => {

    const classes = useStyles();

    const [sortedAttributes, setSortedAttributesTo] = useState<any>();
    const { metaData: filterData } = useMetaDataService();
    const parseAttributes = (jsonString: string) => {
        try {
            return JSON.parse(jsonString);
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return null;
        }
    };

    useEffect(() => {
        if (attributes && Array.isArray(attributes)) {
            const sortedAttrs = sortSingleUpcAttributes(attributes, filterData.attributes);
            setSortedAttributesTo(sortedAttrs);
        }
    }, [attributes]);

    const getAttributeValue = (attributeName: string, attributeValue: string) => {
        try {
            if(attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
                return attributeValue;
            }
              const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
            return (
                <div className="flex items-center">
                    <div className="w-6 h-4 mr-2 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                    <span>{attributeValue}</span>
                </div>
            )
        } catch (error) {
            return attributeValue;
        }
	}

    return (
        <>
            <div className={`${classes.webContainer} gap-y-4 text-base font-semibold`}>
                <div className={`${classes.heading}`}>Specifications</div>
                <div className={`flex flex-wrap rounded-2xl !pr-3 ${classes.container} font-medium`}>
                    {sortedAttributes && sortedAttributes.map((attribute: any, index: any) => {
                        if(attribute.name.trim().toLowerCase() === "hardness"){
                            return null;
                        } else {
                            return (
                                <div className={`flex flex-col !py-4 m-auto flex-1 ${classes.attribute}`} key={index}>
                                    <div className={`${classes.attributeNames} pb-2.5 justify-center flex text-sm`}>
                                        <div className={`text-center ${classes.attributeContainer}`}>
                                            <span className={`text-sm font-medium mr-1 ${classes.attributeName}`}>{attribute.name}</span>
                                            {attribute.uom && (
                                                <span className={`text-xs font-normal ${classes.attributOption}`}>({attribute.uom.toLowerCase()}) </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`${classes.attributes} rounded-lg m-auto`}>
                                        <div className={`${classes.attributeValue} flex justify-center items-center text-center !py-0 rounded-lg text-sm font-normal`}>
                                            {getAttributeValue(attribute.name, attribute.attributeOptions)} 
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>

            <div className={`${classes.mobileContainer} gap-y-3`}>
                <div className={`${classes.heading} text-base font-semibold`}>Specifications</div>
                <div className={`flex flex-wrap gap-y-3 rounded-2xl ${classes.container}`}>
                    {sortedAttributes && sortedAttributes.map((attribute: any, index: any) => {
                        if(attribute.name.trim().toLowerCase() === "hardness"){
                            return null;
                        }else{
                        return (
                            <div className={`flex flex-col m-auto flex-1 ${classes.attribute}`} key={index}>
                                <div className={`${classes.attributeNames} pb-2 justify-center flex`}>
                                    <div className={`text-center ${classes.attributeContainer}`}>
                                        <span className={`text-xs mr-1 ${classes.attributeName}`}>{attribute.name}</span>
                                        {attribute.uom && (
                                            <span className={`text-xs ${classes.attributOption}`}>({attribute.uom.toLowerCase()}) </span>
                                        )}
                                    </div>
                                </div>
                                <div className={`${classes.attributes} rounded-lg m-auto`}>
                                    <div className={`${classes.attributeValue} flex justify-center items-center text-center !py-0 rounded-lg text-sm font-normal`}>
                                        {getAttributeValue(attribute.name, attribute.attributeOptions)}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    })}
                </div>
            </div>
        </>
    );
}

export default SingleUpcAttributeReviewForm;