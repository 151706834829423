import { createUseStyles } from 'react-jss';
import CloseIcon from '../../../assets/images/cross.svg';
import SuccessPopupIcon from '../../../assets/images/successPopupIcon.svg';
import FailurePopupIcon from '../../../assets/images/failurePopupIcon.svg';
import infoPopupIcon from '../../../assets/images/infoPopupIcon.svg';
import Button from '../../atoms/Button/Button';
import { description } from '../../templates/BussinessProfile/BussinessProfileAboutUsEditTemplate';

interface ButtonProps {
    text: string,
    variant: CustomVariant,
    size: CustomSize,
    onClick: () => void,
}

interface SuccessFailurePopupProps {
    width?: string,
    height?: string,
    status: boolean,
    isInfo?: boolean,
    heading: string,
    setShowPopup: (value: boolean) => void,
    button1?: ButtonProps,
    button2?: ButtonProps,
    button3?: ButtonProps,
    description?: string,
    successIcon?: any,
    failureIcon? : any,
}

type CustomVariant = "contained" | "outlined" | "transparent" | "text" | "containedLight" | "underlined" | "containedDark" | "primaryContained" | "secondaryContained" | "tertiaryContained" | "primaryText" | "secondaryText" | "tertiaryText";
type CustomSize = "small" | "medium" | "large";

const useStyles = createUseStyles((theme: any) => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `${theme.palette.background.primaryLightAccent}80`,
        zIndex: 99,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    primaryContainer: {
        width: "458px",
        background: theme.palette.v4.background.secondaryLightAccent,
        boxShadow: `0px 5px 16px 0px ${theme.palette.v4.border.secondaryLight}40`,
        zIndex: 1,
    },
    description: {
        color: theme.palette.v3.text.primaryLight,
        marginTop: "12px",
        fontSize: "14px"
    },
    btn: {
        columnGap: "16px",
        marginTop: "32px",
        marginBottom: "32px"
    },
    heading: {
        fontSize: "36px",
        paddingTop: "24px"
    },
    infoHeading: {
        fontSize: "36px",
        paddingTop: "24px",
        color: "#FD6202"
    },
    "@media (max-width: 480px)": {
        primaryContainer: {
            width: "280px",
        },
        description: {
            marginTop: "8px",
            fontSize: "12px"
        },
        btn: {
            columnGap: "12px",
            marginTop: "24px",
            marginBottom: "32px"
        },
        heading: {
            fontSize: "24px",
            lineHeight: "30px"
        }
    },
}))

const SuccessFailurePopup: React.FC<SuccessFailurePopupProps> = ({
    width,
    height,
    status,
    heading,
    isInfo=false,
    setShowPopup,
    button1,
    button2,
    button3,
    description,
    successIcon,
    failureIcon,
}) => {

    const classes = useStyles();

    const divStyle = {
        height,
        width,
    };

    const handleClose = () => {
        setShowPopup(false);
    }

    return (
        <div className={classes.overlay}>
            <div className={`${classes.primaryContainer} rounded-3xl relative overflow-hidden`} style={divStyle}>
                <img src={CloseIcon} alt="icon" className='absolute right-6 top-6 w-6 h-6 cursor-pointer' onClick={handleClose} />
                <div className="flex flex-col items-center">
                    <>
                        <img
                            src={
                                isInfo
                                    ? infoPopupIcon
                                    : status
                                        ? successIcon || SuccessPopupIcon
                                        : failureIcon || FailurePopupIcon
                            }
                            alt="img"
                            className=""
                        />
                        {isInfo ? (
                            <span className={`${classes.infoHeading} text-center text-4xl font-semibold`}>
                                {heading}
                            </span>
                        ) : (
                            <span className={`${classes.heading} text-4xl font-semibold ${status ? '!text-emerald-600' : '!text-red-600'}`}>
                                {heading}
                            </span>
                        )}
                        <p className={`${classes.description} px-4 text-center font-normal`}>{description}</p>
                    </>

                    <div className={`${classes.btn} flex w-4/5 justify-center`}>
                        {button1 && <Button
                        className='!px-4'
                            variant={button1.variant}
                            label={button1.text}
                            size={button1.size}
                            onClick={button1.onClick}
                        />}
                        {button2 && <Button
                          className='!px-4'
                            variant={button2.variant}
                            label={button2.text}
                            size={button2.size}
                            onClick={button2.onClick}
                        />}
                    </div>
                    {button3 && <div className={`flex w-4/5 justify-center`}>
                        <Button
                            className='!px-4 !mb-8'
                            variant={button3.variant}
                            label={button3.text}
                            size={button3.size}
                            onClick={button3.onClick}
                        />
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default SuccessFailurePopup;