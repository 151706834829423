import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import ProductViewTemplate from "../DashBoard/Inventory Management/ProductView.template";
import ProductCatalogueDetailsViewTabsTemplate from "./ProductCatalogueDetailsViewTabs.template";
import TabsV2, { ITabsSchema } from "../../molecules/TabsV2/TabsV2";
import SuccessFailurePopup from "../../molecules/SuccessFailurePopup/SuccessFailurePopup";
import { usePostProductService } from "../../../services/usePostProductService";
import { IProductSpecification } from "../../pages/Catalogue/SecondaryCatalogueDetails.page";
import { IProductData } from "../DashBoard/Inventory Management/SelectProduct.template";
import { HTTP_STATUS, STATUS } from "../../../utils/types";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import deletePopupIcon from '../../../assets/images/deletePopupIcon.svg';
import { VENDOR_PRODUCT_ROUTES, statusOptions } from "../../../utils/constant";
import { capitalizeFirstLetter } from "../../../utils/helper";
import { useVendorInventoryService } from "../../../services/useVendorInventoryService";

interface IWarehouseDetails {
    warehouseId: number;
    warehouseName: string;
    minimumOrderQuantity: number;
    deliveryTimeFrame: string;
}

export interface IProductSpecifications {
    name: string;
    minValue: string;
    maxValue: string;
    uom: string;
}

export interface IDetails {
    id: number;
    upcCode: string;
    attributes: {
        productSpecification: IProductSpecifications[];
    };
}
export interface IproductDetails {
    id: number;
    warehouseWiseProductDetails: IWarehouseDetails[];
    standardProductDetails: IDetails;
    productCategoryImageURL: string;
    productUpcTitle: string;
    productType: string;
    productId: number;
    status: string;
}

export interface IUsnDetails {
    locations: number;
    totalUsns?: number;
    totalQuantity: number;
    price?: number; 
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
    },
    heading1: {
        color: theme.palette.textV2.primary.primary400,
    },
}));


const ProductCatalogueViewTemplate: React.FC = () => {
    const initialTab = "1";
    const [activeTab, setActiveTabTo] = useState(initialTab);
    const [isDeletePopupVisible, setDeletePopVisibleTo] = useState(false);
    const [productDetails, setproductDetailsTo] = useState<IproductDetails | null>(null);
    const [productsData, setProductsData] = useState<IProductData | null>(null);
    const [status, setStatus] = useState<string>("");
    const [warehouses, setWarehousesTo] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const classes = useStyles();
    const productService = usePostProductService();
    const vendorInventoryService = useVendorInventoryService();
    const navigate = useNavigate();
    const params = useParams();
    const vendorProductId = Number(params.vendorProductId);
    const { user } = useAuthenticatedUser();

    const formatProductPrices = (warehouses: { id: number, name: string }[], vendorInventoryData: any) => {
        const formattedProductPrices = warehouses.map((warehouse) => {
            const matchedProductPrice = vendorInventoryData.find((inventory: any) => inventory.warehouseId === warehouse.id);
            return matchedProductPrice
                ? {
                    warehouseId: matchedProductPrice.warehouseId,
                    deliveryTimeFrame: matchedProductPrice.deliveryTimeFrame,
                    minimumOrderQuantity: matchedProductPrice.minimumOrderQuantity,
                    warehouseName: warehouse.name
                }
                : {
                    warehouseId: warehouse.id,
                    deliveryTimeFrame: "IMMEDIATE",
                    minimumOrderQuantity: 0,
                    warehouseName: warehouse.name
                };
        });
        return formattedProductPrices;
    };

    const getVendorProductDetails = async () => {
        if (user?.businessId) {
            try {
                const productResponse = await productService.getProductById(user?.businessId, vendorProductId);
                if (productResponse?.data?.data) {
                    const content: IproductDetails = productResponse.data.data;
                    const warehouseDetails = content?.warehouseWiseProductDetails;
                    const formattedProductPrices = formatProductPrices(warehouses, warehouseDetails);
                    content.warehouseWiseProductDetails = formattedProductPrices;
                    setproductDetailsTo(content);
                    setStatus(content.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE);
                    setProductsData({
                        id: content.id,
                        productId: content.productId,
                        skuCode: content.standardProductDetails.upcCode,
                        productName: content.productUpcTitle,
                        standardProductDetails: content.standardProductDetails
                    });
                } else {
                    throw new Error("Products fetch failed");
                }
            } catch (error) {
                console.error("Error fetching product details:", error);
                setproductDetailsTo(null);
            }
        }
    };

    const schema: ITabsSchema[] = (productDetails?.warehouseWiseProductDetails ?? []).map((detail: IWarehouseDetails) => ({
        label: capitalizeFirstLetter(detail.warehouseName),
        component: (
            <ProductCatalogueDetailsViewTabsTemplate
                key={detail.warehouseId}
                deliveryTimeFrame={detail.deliveryTimeFrame}
                minimumOrderQuantity={detail.minimumOrderQuantity}
            />
        )
    }));

    const confirmInactive = async () => {
        if (user?.businessId) {
            try {
                const upsertResponse = await productService.upsertVendorProduct(user?.businessId, { productId: productDetails?.productId ?? 0, classType: productDetails?.productType ?? "", status: status ?? "INACTIVE", warehouseWiseProductDetails: productDetails?.warehouseWiseProductDetails ?? [] });
                if (upsertResponse?.status === HTTP_STATUS.OK) {
                    showSnackbar('success', 'Vendor Product Updated Successfully');
                }
                else {
                    showSnackbar('error', 'Vendor Product Updation Failed');
                    throw new Error("Updated Vendor Product");
                }
            } catch (error) {
                console.error("error", `Updating Vendor Product failed`)
            }
            setDeletePopVisibleTo(false);
            navigate(VENDOR_PRODUCT_ROUTES.POST_PRODUCT);
        }
        else {
            showSnackbar('error', 'Vendor Product Updation Failed');
        }
    }

    const handleInactiveOnClick = () => {
        setDeletePopVisibleTo(true);
    }
    const handleTabChange = (tab: string | number) => {
        setActiveTabTo(tab.toString());
    };
    const cancelInactive = () => {
        setDeletePopVisibleTo(false);
    };

    const fetchWarehouses = async () => {
        if (user?.businessId) {
            try {
                const warehousesResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId, status: STATUS.ACTIVE });
                if (warehousesResponse.status === HTTP_STATUS.OK) {
                    const warehouses = warehousesResponse?.data?.data?.content;
                    setWarehousesTo(warehouses);
                }
            } catch (error) {
                showSnackbar('error', 'Warehouses fetch failed');
            }
        }
    };

    useEffect(() => {
        if (warehouses.length > 0 && user?.businessId)
            getVendorProductDetails();
    }, [params.vendorProductId, warehouses, user?.businessId]);

    useEffect(() => {
        fetchWarehouses();
    }, [user?.businessId]);

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);

    return (
        <div className="">
            {isDeletePopupVisible && (
                <SuccessFailurePopup
                    width="458px"
                    height="365px"
                    status={status !== "INACTIVE"}
                    failureIcon={deletePopupIcon}
                    heading={`${statusOptions.find(option => option.value === status)?.label} Product?`}
                    description={`Are you sure you want to make this product ${statusOptions.find(option => option.value === status)?.label}?`}
                    setShowPopup={setDeletePopVisibleTo}
                    button1={{
                        text: 'Yes',
                        variant: 'tertiaryContained',
                        size: 'large',
                        onClick: confirmInactive,
                    }}
                    button2={{
                        text: 'No',
                        variant: 'secondaryContained',
                        size: 'large',
                        onClick: cancelInactive,
                    }}
                />
            )}

            <div className="flex justify-between">
                <div className="flex">
                    <div className={`${classes.heading} font-medium text-lg`}>View Product: </div>
                    <div className={`${classes.heading1} font-medium text-lg ml-1`}>{productDetails?.standardProductDetails.upcCode}</div>
                </div>
                <div className="flex justify-end">  <Button label={`Make Product ${statusOptions.find(option => option.value === status)?.label}`} variant="secondaryContained" onClick={handleInactiveOnClick} /></div>
            </div>
            <div className="mt-6">
                <ProductViewTemplate product={productsData} />
            </div>
            <div className='mt-6'>
                <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />
            </div>
            <div className="flex gap-x-4 mt-6 justify-end">
                <Button label={"Back"} variant="tertiaryContained" onClick={() => { navigate(VENDOR_PRODUCT_ROUTES.POST_PRODUCT); }} />
                <Button label={"Edit"}
                    variant="primaryContained"
                    disabled={!productDetails?.warehouseWiseProductDetails || productDetails.warehouseWiseProductDetails.length === 0}
                    onClick={() => { navigate(`${VENDOR_PRODUCT_ROUTES.EDIT_PRODUCT}/${vendorProductId}`); }} />
            </div>
        </div>
    );
};

export default ProductCatalogueViewTemplate;