import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { FormHelperText } from '@mui/material';
import { VendorCatalogueSectionProp } from './VendorCatalogueForm.template';
import { VENDOR_CATALOGUE_FORM_STATES } from '../../../utils/types';
import { IVendorCatalogueAddForm } from '../../pages/VendorCatalogue/VendorCatalogueAdd.page';
import CustomRadioGroup from '../../molecules/CustomRadioGroup/CustomRadioGroup';
import Button from '../../atoms/Button/Button';
import { CATALOGUE_TYPE, radioGroupOptionCatalog, VENDOR_CATALOGUE_ROUTES } from '../../../utils/constant';


const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
    textHeading2: {
        color: theme.palette.textV2.primary.primary900
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    radioColor: {
        color: theme.palette.textV2.tertiary.tertiary700,
        width: '31%'
    }
}));
const SelectCatalogTypeFormTemplate: React.FC<VendorCatalogueSectionProp> = ({ formik, setCurrentSectionTo, mode }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleCancel = () => {
        navigate(VENDOR_CATALOGUE_ROUTES.MAKE_CATALOGUE);
    };
    const onNext = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields = ["catalogueType"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
            if (formik.values.catalogueType == '') {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        formik.setValues((prevValues: IVendorCatalogueAddForm) => ({
            ...prevValues,
        }));
        if (fieldError === true) return;
        setCurrentSectionTo(VENDOR_CATALOGUE_FORM_STATES.PRODUCT_TYPE);
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value == CATALOGUE_TYPE.SINGLE_PRODUCT) {
            mode === "ADD" &&   formik.setFieldValue("catalogueType", event.target.value);
        }
    };
    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);
    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-3/5 ">
                    <div className='flex gap-x-2 mx-auto '>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Select Catalog Type </div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5 ">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product </div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Enter Pricing Details</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Customisation </div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className=' grid gap-4'>
                <div className=' grid gap-1/2'>
                    <text className={`${classes.textHeading} text-base font-medium`}> Select Catalog Type</text>
                    <text className={`${classes.barHeading} text-xs font-normal`}> Please select the catalog type you want to create</text>
                </div>
                <div className={`${classes.selectBox} p-6 rounded-xl grid gap-4 border-3`}>
                    <text className={`${classes.textHeading2}text-base font-medium`}>Select Catalog Type</text>
                    <CustomRadioGroup
                        options={radioGroupOptionCatalog}
                        value={formik.values.catalogueType}
                        onChange={handleChange}
                        className={`${classes.radioColor} `} />
                    {formik.touched?.catalogueType && formik.errors?.catalogueType &&
                        <FormHelperText error className='top-half '>{formik.errors?.catalogueType}</FormHelperText>
                    }
                </div>
            </div>

            <div className='flex items-end gap-6 justify-self-end'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
                <Button
                    variant="primaryContained"
                    label="Next"
                    onClick={onNext}
                />
            </div>
        </div>
    )
}

export default SelectCatalogTypeFormTemplate